import React, { useEffect, useRef, useState } from "react";
import { Link, Routes } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  SettingIcon,
  NotificationIcon,
  RefreshIcon,
  MailSettingIcon,
} from "../../../icons/generalIcons/Icons";
import { ROUTES } from "../../../reactRoute/RouteConstants";
import ProfileDropdown from "../dropDowns/ProfileDropDown";
import Logout from "../../../pages/logout/Logout";
import Image from "../image/Image";
import MailIcon from "../../../icons/productIcons/MailIcon";
import DriveIcons from "../../../icons/productIcons/DriveIcons";
import PasswordIcon from "../../../icons/productIcons/PasswordIcon";
import AddUpdateEmailSetting from "../modals/AddUpdateEmailSetting";

const Navbar = () => {
  const profileModal = useRef(null);
  const [openProfileDrop, setOpenProfileDrop] = useState(false);
  const [emailSettingModal, setEmailSettingModal] = useState(false);
  console.log(emailSettingModal,"emailSettingModal");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileModal.current &&
        !profileModal.current.contains(event.target)
      ) {
        setOpenProfileDrop(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setOpenProfileDrop(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <div className="flex items-center bg-F9F7F7 pr-[10px] py-[6px] min-h-[56px] w-full z-[1] justify-end max-lg:justify-between max-sm:pl-[10px] relative">
      {/* <AddUpdateEmailSetting
        emailSettingModal={emailSettingModal}
        setEmailSettingModal={setEmailSettingModal}
        cancel={() => setEmailSettingModal(false)}
      /> */}
      <div className="flex items-center gap-[4px] z-[1] lg:hidden">
        <Tooltip title="Jurii Mail" arrow disableInteractive placement="bottom">
          <Link
            to={ROUTES.inbox}
            className={`product__container w-[100%] h-[38px] bg-DBE2EF cursor-pointer transition rounded-tl-[20px] p-5  rounded-bl-[20px]  shadow flex items-center justify-center `}
          >
            <MailIcon className={"drive-icon cursor-pointer"} />
          </Link>
        </Tooltip>
        <Tooltip
          title="Jurii Drive"
          arrow
          disableInteractive
          placement="bottom"
        >
          <Link
            to={"drive"}
            className={`product__container w-[100%] h-[38px] bg-DBE2EF p-5  cursor-pointer transition rounded-[1px] shadow flex items-center justify-center `}
          >
            <DriveIcons className={"drive-icon cursor-pointer"} />
          </Link>
        </Tooltip>
        <Tooltip
          title="Jurii Password Manager"
          arrow
          disableInteractive
          placement="right"
        >
          <Link
            to={"logins"}
            className={`product__container w-[100%] h-[38px] bg-DBE2EF p-5  cursor-pointer transition rounded-[1px] rounded-tr-[20px] rounded-br-[20px] shadow flex items-center justify-center `}
          >
            <PasswordIcon className={"drive-icon cursor-pointer"} />
          </Link>
        </Tooltip>
      </div>
      <div className="flex items-center gap-[24px] max-lg:gap-[20px] max-md:gap-[16px] max-sm:gap-[10px]">
      {/* <MailSettingIcon
        onClick={() => {
          setEmailSettingModal(true);
        }}
      /> */}
        <div className="relative" ref={profileModal}>
          <ProfileDropdown
            openProfileDrop={openProfileDrop}
            profileModal={profileModal}
          />
          <img
            src="./formsBack.png"
            className="h-[40px] object-cover rounded-full max-w-[40px] hover:border-2 hover:border-[#112D4E] border-2 border-transparent cursor-pointer transition"
            alt=""
            onClick={() => setOpenProfileDrop(!openProfileDrop)}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
