import React, { useEffect, useState } from "react";
import {
  useEmptyTrashMutation,
  useGetTrashDataQuery,
} from "../../redux/juriiDriveApis";
import DriveCard from "../../components/shared/cards/DriveCard";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import useGetPathUrl from "../../lib/useGetPathUrl";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../redux/ConditionalModelReducer";
import { setIsLoading } from "../../redux/LoadingReducer";
import ShowFile from "../../components/shared/modals/ShowFile";
import { Config } from "../../constants/Index";
import useDeleteFileFolder from "../../lib/useDeleteFileFolder";
function Trash() {
  const { pathForFile } = useGetPathUrl();
  const dispatch = useDispatch();
  const { data: trash, isLoading: isLoadingTrash } = useGetTrashDataQuery();
  const [emptyTrashData, { isLoading: isLoadingEmptyTrash }] = useEmptyTrashMutation();
  const { handleRestoreFile, isRestoreFileLoading } = useDeleteFileFolder();

  const [cardGrid, setCardGrid] = useState("square");
  const [src, setSrc] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [fileName, setFileName] = useState();
  const [selectedShowFile, setSelectedShowFile] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const pathForFolder = `/drive/folders/${pathForFile}`;
  const trashFiles = trash?.data?.files;
  const trashFolders = trash?.data?.folders;
  const isFilesExist = trashFiles?.length > 0;
  const isFoldersExist = trashFolders?.length > 0;

  const emptyTrash = async () => {
    try {
      await emptyTrashData({ data: "" });
      dispatch(hideModal());
    } catch (error) {
      console.error("An error occurred while emptying the trash:", error);
    }
  };

  useEffect(() => {
    dispatch(setIsLoading(isLoadingEmptyTrash));
  }, [isLoadingEmptyTrash]);

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        solid: true,
        isVisible: true,
        title: "Empty Trash",
        message: "Are you sure to delete all items permanently?",
        okCallback: () => {
          emptyTrash();
        },
        cancelCallback: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  const fnOnDoubleClick = (id) => {
    setSelectedFolderId(id);
    setShowRestoreModal(true);
  };

  const fnOnCancel = () => {
    setSelectedFolderId(null);
    setShowRestoreModal(false);
  };

  const fnOnRestore = () => {
    handleRestoreFile(selectedFolderId, fnOnCancel);
  };

  const ViewFolders = () => {
    return (
      isFoldersExist && (
        <>
          <div className="text-112D4E font-[500] text-[20px]">Folders</div>
          <div
            className={`${cardGrid === "square"
              ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
              : " flex flex-col "
              } gap-[24px]`}
          >
            {isFoldersExist && trashFolders?.map((folder, index) => (
              <DriveCard
                cardGrid={cardGrid}
                cardType="folder"
                name={folder?.name?.split("/")?.pop()}
                pathName={pathForFolder}
                folder={folder}
                index={index}
                offDragDrop={true}
                trash={true}
                key={index}
                onDoubleClick={() => fnOnDoubleClick(folder?.id)}
              />
            ))}
          </div>
        </>
      )
    );
  };

  const TrashButton = () => {
    return (
      <>
        <div className="flex flex-row items-center justify-between w-full gap-[14px]">
          <div className="text-112D4E font-[500] text-[22px]">Trash</div>
          <DZBtn
            solid={true}
            children={"Empty Trash"}
            className={"!rounded-full"}
            onClick={() => handleDeleteClick()}
          />
        </div>
      </>
    );
  };

  const ViewFiles = () => {
    return (
      isFilesExist && (
        <>
          <div className="text-112D4E font-[500] text-[20px]">Files</div>
          <div
            className={`${cardGrid === "square"
              ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
              : " flex flex-col "
              } gap-[24px]`}
          >
            {isFilesExist &&
              trashFiles?.map((folder, index) => (
                <span onDoubleClick={() => {
                  setSrc(`${Config.baseProductUrl}${folder?.name}`);
                  setSelectedShowFile(folder);
                  setShowFile(true);
                  setFileName(folder?.name);
                }}>
                  <DriveCard
                    cardGrid={cardGrid}
                    cardType="file"
                    folder={folder}
                    pathName={pathForFile}
                    name={folder?.name?.split("/")?.pop()}
                    index={index}
                    offDragDrop={true}
                    trash={true}
                  />
                </span>
              ))}
          </div>
        </>
      )
    );
  };

  return (
    <>

      {showFile && src && (
        <ShowFile
          showFile={showFile}
          url={src}
          pathName={pathForFile}
          fileName={fileName}
          selectedShowFile={selectedShowFile}
          setShowFile={setShowFile}
        />
      )}

      {isLoadingTrash ? (
        <SimpleLoader />
      ) : isFilesExist || isFoldersExist ? (
        <div className="flex flex-col gap-[24px] w-full h-[100%] min-h-[100%] overflow-y-scroll">
          <TrashButton />
          <ViewFolders />
          <ViewFiles />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-[14px]">
            <div className="text-112D4E font-[500] text-[22px]">Trash</div>
          </div>
          <EmptyLoader />
        </>
      )}

      {
        showRestoreModal && <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.3)] z-[10] flex items-center justify-center">
          <div className="flex flex-col gap-[20px] bg-[white] p-[24px] rounded-md max-w-[450px]" >
            <h3 className="text-[18px] font-medium text-center" >This file is in your trash</h3>
            <p>To view this folder, you need to restore it from your trash</p>
            <div className="flex items-center justify-around gap-[24px]" >
              <DZBtn outline={true} className={'rounded-md w-full'}  onClick={fnOnCancel} >Cancel</DZBtn>
              <DZBtn solid={true} className={'rounded-md w-full'} onClick={fnOnRestore} >{isRestoreFileLoading ? 'Loading...' : 'Restore'}</DZBtn>
            </div>
          </div>
        </div>
      }

    </>
  );
}

export default Trash;
