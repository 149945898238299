import { Tooltip, Zoom } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";

import PhotoIcon from "../../../icons/cardIcons/PhotoIcon";

import {
  CrossIcon,
  DeleteIcon,
  StarIcon,
  DownloadIcon,
  MaximizeIcon,
  MinusIcon,
} from "../../../icons/generalIcons/Icons";

import useFindFileType from "../../../lib/customHooks/useFIndFileType";
import ShowPdf from "../../juriDrive/showPdf/ShowPdf";
import ShowVideoPlayer from "../../juriDrive/showVideoPlayer/ShowVideoPlayer";
import Image from "../image/Image";
import useOutsideClick from "../../../lib/useOutsideClick";
import useFullScreen from "../../../lib/useFullScreen";
import useDownloadFileFolder from "../../../lib/useDownloadFileFolder";
import { Config } from "../../../constants/Index";
import useHandleDriveStar from "../../../lib/useHandleDriveStar";
import { hideModal, showModal } from "../../../redux/ConditionalModelReducer";
import { useDispatch } from "react-redux";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder";
import { useDownloadManager } from "../../../lib/customHooks/useDownloadManager";

const ShowFile = ({ setShowFile,selectedShowFile, url, fileName, pathName }) => {
  const { handleDelete, isDeleteLoading } = useDeleteFileFolder();
  const dispatch = useDispatch();
  const { handleFavorites, isFavLoading, isAlreadyFav } = useHandleDriveStar(
    `${fileName}`,
    "file"
  );
  
  const onClickDelete = () => {
    dispatch(
      showModal({
        isVisible: true,
        title: "Move to trash!",
        message: "Are you sure you want to move this in Trash?",
        okCallback: () => handleDelete(`${pathName}/${fileName}`),
        cancelCallback: () => {
          setShowFile(false);
          dispatch(hideModal());
        },
        isLoading: isDeleteLoading,
      })
    );
  };
  const { handleDownload, isDownloading } = useDownloadManager();
  const modalRef = useRef(null);
  const { findFileType, isImg, isPdf, isVideo, isAudio, isDoc, isTxt } =
    useFindFileType();
  useEffect(() => {
    findFileType(url);
  }, [url]);
  useOutsideClick(modalRef, () => handleClose());
  const handleClose = () => {
    setShowFile(false);
  };
  return (
    <>
      <div className="fixed inset-0 flex flex-col gap-[20px] sm:gap-[80px] bg-[#02060C] sm:pb-[80px] p-[20px] z-[50] h-full">
        <div className="flex items-center justify-between gap-[32px] p-[24px] bg-[#FFFFFF10] rounded-[24px]">
          <span className="text-[#FFF]">{fileName}</span>
          <div className="flex items-center gap-[16px]">
            <StarIcon
              iconColor="#FFF"
              onClick={(e) => { e.preventDefault(); handleFavorites(); }}
              className={`cursor-pointer w-[20px] h-[20px] ${
                isFavLoading ? "vibrate-anime" : ""
              }`}
              bgColor={isAlreadyFav ? "#ffffff" : ""}
            />
            <DeleteIcon
              onClick={() => onClickDelete()}
              iconColor="#FFF"
              className={"w-[20px] h-[20px] cursor-pointer"}
            />
            <DownloadIcon
              onClick={() => handleDownload(selectedShowFile)}
              iconColor="#FFF"
              className={"w-[20px] h-[20px] cursor-pointer"}
            />
            <CrossIcon
              onClick={() => handleClose()}
              iconColor="#FFF"
              className={"w-[24px] h-[24px] cursor-pointer"}
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-full overflow-hidden">
          {isImg ? (
            <img
              alt="That Wanaka Tree, New Zealand by Laura Smetsers"
              src={url}
              className={`object-cover h-full w-auto rounded-[24px]`}
            />
          ) : isVideo ? (
            <ShowVideoPlayer url={url} />
          ) : isPdf ? (
            <ShowPdf className={` w-full h-full rounded-[24px]`} pdfUrl={url} />
          ) : isAudio ? (
            <audio controls className="w-full">
              <source src={url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <p className="text-white">File type not supported</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowFile;
