import React, { useEffect, useRef, useState, useCallback } from "react";
import { useGetDriveFolderQuery } from "../../redux/juriiDriveApis";
import { Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { selectIsCardSquare } from "../../redux/CardGridReducer";
import { selectCreateFolder } from "../../redux/CreateFolderReducer";
import { selectItemTypeModel } from "../../redux/DriveFilterReducer";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import ShowFile from "../../components/shared/modals/ShowFile";
import { Config } from "../../constants/Index";
import useGetPathUrl from "../../lib/useGetPathUrl";
import CreateDropDown from "../../components/shared/dropDowns/CreateDropDown";
import useOutsideClick from "../../lib/useOutsideClick";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { SearchIcon } from "../../icons/generalIcons/Icons";
import { useSearchManager } from "../../lib/customHooks/useSearchManager";
import useSelectAllItems from "../../lib/useSelectAllItems";
import ViewFolders from "./ViewFolders";
import ViewFiles from "./ViewFiles";
import { BreadCrumb } from "../../components/common/BreadCrumb";
import DZSearchBar from "../../components/shared/searchbar/DZSearchbar";

const Folder = React.memo(() => {
  const createDropDownRef = useRef(null);
  const [selectedShowFile, setSelectedShowFile] = useState(false);
  const { pathForFile } = useGetPathUrl();
  const breadcrumbs = useSelector((state) => state.BreadCrumbReducer);
  const isCardSquare = useSelector(selectIsCardSquare);
  const createFolderSlug = useSelector(selectCreateFolder);
  const itemTypeModel = useSelector(selectItemTypeModel);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [showCreatingFolder, setShowCreatingFolder] = useState(false);
  const [pathSlug, setPathSlug] = useState();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [src, setSrc] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [fileName, setFileName] = useState();
  const [loader, setLoader] = useState(false);

  const {
    data: folderData,
    isLoading: isLoadingFolderData,
    error,
    refetch,
  } = useGetDriveFolderQuery(pathForFile);

  const {
    searchLoader,
    fnOnSearchInputChange,
    fnOnSearch,
    fnOnEnter,
    fnFilterFilesAndFolders,
  } = useSearchManager();

  const filteredFolders = fnFilterFilesAndFolders(folders);
  const filteredFiles = fnFilterFilesAndFolders(files);

  const isFoldersExist = filteredFolders?.length > 0;
  const isFilesExist = filteredFiles?.length > 0;

  const updateFilesPath = filteredFiles?.map((file) => ({
    ...file,
    name: `${pathForFile}/${file.name}`,
  }));

  const updateFoldersPath = filteredFolders?.map((folder) => ({
    ...folder,
    name: `${pathForFile}/${folder.name}`,
  }));

  useSelectAllItems([...updateFilesPath, ...updateFoldersPath]);

  useEffect(() => {
    if (breadcrumbs.length > 0) {
      setPathSlug(breadcrumbs?.map((crumb) => crumb)?.join("/"));
      refetch();
    }
  }, [breadcrumbs, refetch]);

  useEffect(() => {
    refetch();
  }, [createFolderSlug, refetch]);

  useEffect(() => {
    setLoader(true);
  }, [pathForFile]);

  useEffect(() => {
    if (error) {
      setFiles([]);
      setFolders([]);
      setLoader(false);
    } else {
      setFolders(folderData?.data?.folders || []);
      setFiles(folderData?.data?.files || []);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [folderData, error]);

  useOutsideClick(createDropDownRef, () => setShowCreatingFolder(false));

  const fnRightClick = useCallback((event) => {
    event.preventDefault();
    setShowCreatingFolder(true);
    setModalPosition({ x: event.pageX, y: event.pageY });
  }, []);

  return (
    <>
      <div
        onContextMenu={fnRightClick}
        className="flex flex-col w-full gap-[20px] h-[84dvh] min-h-[100%] overflow-y-scroll"
      >
        <BreadCrumb />

        <DZSearchBar
          onChange={(event) => fnOnSearchInputChange(event)}
          onKeyDown={fnOnEnter}
          onClick={fnOnSearch}
        />

        <div className="flex flex-col gap-[20px] overflow-y-scroll max-h-[100vh] last:pb-[54px] flex-1">
          {isLoadingFolderData || loader ? (
            <SimpleLoader />
          ) : isFilesExist || isFoldersExist ? (
            <>
              <ViewFolders
                filteredFolders={filteredFolders}
                itemTypeModel={itemTypeModel}
                isFoldersExist={isFoldersExist}
                pathForFile={pathForFile}
              />
              <ViewFiles
                filteredFiles={filteredFiles}
                itemTypeModel={itemTypeModel}
                isFilesExist={isFilesExist}
                pathForFile={pathForFile}
                setSrc={setSrc}
                setSelectedShowFile={setSelectedShowFile}
                setShowFile={setShowFile}
                setFileName={setFileName}
              />
            </>
          ) : (
            <EmptyLoader />
          )}
        </div>
        {src && showFile && (
          <ShowFile
            showFile={showFile}
            pathName={pathForFile}
            url={src}
            fileName={fileName}
            selectedShowFile={selectedShowFile}
            setShowFile={setShowFile}
          />
        )}
      </div>
      {showCreatingFolder && (
        <CreateDropDown
          dropRef={createDropDownRef}
          showCreatingFolder={showCreatingFolder}
          setShowCreatingFolder={setShowCreatingFolder}
          className="absolute z-[2] !h-fit w-fit shadow"
          style={{ top: modalPosition.y, left: modalPosition.x }}
        />
      )}
    </>
  );
});

export default Folder;
