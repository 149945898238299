import React, { useState } from "react";
import MailCard from "../../components/shared/mailCard/MailCard";
import MailDetail from "../../components/shared/mailDetail/MailDetail";
import { useGetSentEmailListQuery } from "../../redux/storeApis";
import useMailDetailLogic from "../../lib/customHooks/useMailDetailLogic";
import OperationBar from "../../components/opreationBar/OperationBar";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import { ClockIcon, CrossIcon, SearchIcon, StarIcon } from "../../icons/generalIcons/Icons";
import DZText from "../../components/shared/text/DZText";
import { Tooltip } from "@mui/material";
import CheckInput from "../../components/shared/checkInput/CheckInput";
import InputField from "../../components/shared/inputField/InputField";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../redux/LoadingReducer";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useSearchManager } from "../../lib/customHooks/useSearchManager";
import { Config, EMAIL_TYPE } from "../../constants/Index";
import DZSearchBar from "../../components/shared/searchbar/DZSearchbar";

const Sent = () => {
  const { data: emailsData, isLoading: isLoadingEmails } = useGetSentEmailListQuery();
  const { mailDetailStatus, mailId, handleMailDetail, openMailDetail } = useMailDetailLogic();
  const isShowLoader = useSelector(selectIsLoading);

  const { searchLoader, fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterEmails } = useSearchManager();

  const [emailDetail, setEmailDetail] = useState(null);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(null);

  const filteredEmails = fnFilterEmails(emailsData, EMAIL_TYPE.sent);
  const isExistSentEmails = filteredEmails?.length > 0;

  const fnOnClickEmail = (email, index) => {
    if (selectedEmailIndex != index) {
      setEmailDetail(email);
      setSelectedEmailIndex(index);
    } else {
      setEmailDetail(null);
      setSelectedEmailIndex(null);
    }
  };
  const ViewEmailDetail = () => {
    return (
      <div className="flex flex-col align-start gap-[32px] p-[24px] rounded-[10px] bg-F9F7F7 h-full w-full max-lg:fixed max-lg:left-0 max-lg:bottom-0 max-lg:z-10 max-lg:max-h-[88svh] max-lg:shadow">
        <div className="mail__detailTextArea flex items-center h-fit w-full  justify-between gap-3 max-sm:flex-col max-sm:items-start">
          <div className="flex items-center gap-[20px] max-sm:w-full">
            <img
              src="https://picsum.photos/300"
              className="rounded-full max-w-[56px] max-h-[56px]"
              alt=""
            />
            <DZText
              className="text-112D4E text-[16px] truncate max-w-[170px] min-w-[140px]"
              children={emailDetail?.from}
            />
            <Tooltip
              arrow
              title="Click to star it (you can see it in the starred page)"
            >
              <span className=" cursor-pointer">
                <StarIcon iconColor="#3F72AF" pointer bgColor={"#3F72AF"} />
              </span>
            </Tooltip>
            <span className="w-full flex items-end justify-end sm:hidden">
              <CrossIcon />
            </span>
          </div>
          <div className="flex items-center gap-[4px] min-w-[180px] justify-end">
            <ClockIcon />
            <DZText className="text-3F72AF" children={"8 hours ago"} />
          </div>
          <span className="w-full flex items-end justify-end max-sm:hidden lg:hidden">
            <CrossIcon />
          </span>
        </div>
        <div className="flex flex-col gap-[24px]">
          {/* <div className="flex flex-col gap-[4px]">
          <DZText className={'text-112D4E'} children={'Sender'} />
          <InputField placeholder={'input field'} className={'placeholder:text-[#3F72AF] cursor-arrow  disabled:bg-white'} disabled={true} />
        </div> */}
          <div className="flex flex-col gap-[4px]">
            <DZText
              type={"email"}
              className={"text-112D4E"}
              children={"Subject"}
            />
            <InputField
              type={"text"}
              placeholder={"No subject"}
              className={
                "placeholder:text-[#3F72AF] cursor-arrow  disabled:bg-white"
              }
              disabled={true}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <DZText
              className={"text-112D4E disabled:bg-white"}
              disabled={true}
              children={"Message"}
            />
            <textarea
              type="text"
              disabled
              className=" cursor-arrow sentMail__detailTextArea disabled:bg-white text-3F72AF px-[20px] resize-none h-full min-h-[400px] py-[14px] outline-none rounded-[10px] placeholder:text-[#3F72AF]"
              placeholder={emailDetail?.body_html}
            />
            {/* <div className="external-html-content" dangerouslySetInnerHTML={{__html : emailDetail?.body_html}}  /> */}
          </div>
        </div>
        <div className="flex items-center gap-[20px]">
          <DZBtn
            toolTipText={"Click to Reply"}
            simpleIcon
            className={"cursor-pointer shadow"}
            children={"Reply"}
            btnIcon={
              <ReplyIcon
                className={"cursor-pointer w-[20px] h-[20px]"}
                iconColor="#112D4E"
              />
            }
            textStyle={"cursor-pointer text-112D4E"}
          />
          <DZBtn
            toolTipText={"Click to Forward"}
            simpleIcon
            className={"cursor-pointer flex-row-reverse shadow"}
            children={"Forward"}
            btnIcon={
              <ForwardIcon
                className={"cursor-pointer w-[20px] h-[20px]"}
                iconColor="#112D4E"
              />
            }
            textStyle={"cursor-pointer text-112D4E"}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={`w-full gap-[16px] h-full ${emailDetail ? 'grid grid-cols-2 max-lg:grid-cols-1' : 'flex items-start'}`}>
      <div className="flex flex-col items-center w-full gap-[16px] overflow-y-scroll h-[100dvh] max-sm:pb-[154px] pb-[90px]">

        <DZSearchBar
          onChange={(event) => fnOnSearchInputChange(event)}
          onKeyDown={fnOnEnter}
          onClick={fnOnSearch}
        />

        {isLoadingEmails || isShowLoader ? (
          <SimpleLoader />
        ) : isExistSentEmails ? (
          filteredEmails?.slice()?.reverse()?.map((email, index) => (
            <div onClick={() => fnOnClickEmail(email, index)} className={`flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] max-sm:flex-col max-sm:items-start cursor-pointer ${selectedEmailIndex == index && "!bg-[#86BCFC30]"}`}>

              <Tooltip arrow title="Click to select it">

                <span onClick={(e) => { fnOnClickEmail(email, index); e.stopPropagation(); }} className="cursor-pointer" >
                  <CheckInput active={selectedEmailIndex == index} />
                </span>

              </Tooltip>

              <div className="flex flex-col gap-[4px] max-w-full w-full cursor-pointer">

                <h1 className="cursor-pointer text-start">{Config.fnExtractEmail(email?.to)}</h1>
                <p className="cursor-pointer">{email?.subject}</p>

              </div>

              <div className="flex items-center gap-[8px]">
                <DZText children={Config.fnConvertEmailDate(email?.date)} small className={"text-nowrap text-112D4E cursor-pointer truncate min-w-[40px] w-full text-end"} />
                <ClockIcon iconColor="#112D4E" className={"cursor-pointer"} />
              </div>

            </div>
          ))
        ) : (
          <EmptyLoader />
        )}
      </div>
      {selectedEmailIndex || emailDetail ? (
        <MailDetail
          receiver={Config.fnExtractEmail(emailDetail?.to)}
          subject={emailDetail?.subject}
          body={emailDetail?.body_html ? emailDetail?.body_html : emailDetail?.body_plain}
          date={emailDetail?.date}
          onClose={() => {
            setSelectedEmailIndex(null);
            setEmailDetail(null);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Sent;
