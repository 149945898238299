import React, { useCallback, useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../components/localStorage";
import { useGetUserAccountsQuery } from "../redux/storeApis";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../reactRoute/RouteConstants";
import { KEYS } from "../constants/Index";

function useAllAccountData() {
  const navigate = useNavigate();
  const {
    data: accounts,
    isLoading: isLoadingAllAccounts,
    refetch: refetchAllAccounts,
    isError: isErrorAccounts,
    isSuccess: isSuccessAccounts,
  } = useGetUserAccountsQuery();
  const [accountsData, setAccountsData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    refetchAllAccounts();
    setAccountsData(accounts?.data?.accounts);
  }, [accounts]);

  useEffect(() => {
    setSelectedEmail(getLocalStorage(KEYS.emailId) || accountsData?.[0]?.id);
  }, [accountsData]);

  const handleSelectedEmail = ({emailId, email, navigateToInbox, navigateToDashBoard,navigateTo}) => {
    setLocalStorage(KEYS.emailId, emailId);
    setLocalStorage(KEYS.email, email);
    setSelectedEmail(emailId);
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (navigateToInbox) {
      navigate(ROUTES.inbox);
    }
    if(navigateToDashBoard) {
      navigate(ROUTES.dashboard);
    }
    window.location.reload();
  };

  return {
    accountsData,
    selectedEmail,
    isLoadingAllAccounts,
    isErrorAccounts,
    isSuccessAccounts,
    handleSelectedEmail,

    refetchAllAccounts,
  };
}

export default useAllAccountData;
