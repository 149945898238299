import React, { useState } from "react";
import DZText from "../text/DZText";
import Logout from "../../../pages/logout/Logout";
import { useUserManager } from "../../../lib/customHooks/useUserManager";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../reactRoute/RouteConstants";
import AddUpdateEmailSetting from "../modals/AddUpdateEmailSetting";
import { MailSettingIcon, UserIcon } from "../../../icons/generalIcons/Icons";

const ProfileDropdown = ({ openProfileDrop, profileModal, isShowEmailSettings = true, isShowDashBoard = true }) => {

  const navigate = useNavigate();
  const { userEmail, userName } = useUserManager();

  const [emailSettingModal, setEmailSettingModal] = useState(false);

  return (
    <>
      {openProfileDrop && <div
        className={` ${openProfileDrop ? "openProfileModal" : "closeProfileModal"
          } overflow-hidden flex rounded-[10px] p-[12px] gap-[16px] flex-col absolute top-[120%] right-0 bg-white min-w-[340px] shadow`}
      >
        <AddUpdateEmailSetting
          emailSettingModal={emailSettingModal}
          setEmailSettingModal={setEmailSettingModal}
          cancel={() => setEmailSettingModal(false)}
        />
        <Link to={ROUTES.juriiSettings.accounts} className="flex items-center justify-between cursor-pointer">
          <div className="flex gap-[2px] flex-col cursor-pointer">
            <DZText
              className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
              children={userName}
            />
            <DZText
              className="text-[12px] text-[#112D4E] cursor-pointer"
              children={userEmail}
            />
          </div>
          <img src="https://picsum.photos/40" className="rounded-full cursor-pointer" alt="" />
        </Link>

        {isShowEmailSettings && <span
          onClick={() => setEmailSettingModal(true)}
          className="flex bg-F9F7F7 p-[12px] gap-[12px] rounded-[10px] overflow-hidden cursor-pointer"
        >
          <MailSettingIcon />
          <DZText
            className="text-[14px] text-[#112D4E] cursor-pointer"
            children={"Email Settings"}
          />
        </span>}

        {isShowDashBoard && <span
          onClick={() => navigate(ROUTES.dashboard)}
          className="flex bg-F9F7F7 p-[12px] gap-[12px] rounded-[10px] overflow-hidden cursor-pointer"
        >
          <UserIcon className={'w-5 h-5'} />
          <DZText
            className="text-[14px] text-[#112D4E] cursor-pointer"
            children={"Dashboard"}
          />
        </span>}

        <div className="flex items-center gap-[6px] cursor-pointer group">
          <Logout />
        </div>
      </div>}
    </>
  );
};

export default ProfileDropdown;
