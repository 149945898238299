import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "ComposeModelReducer",
  initialState: {
    fullComposeModel: false,
    openComposeModel: false,
    composeModel: true,
    isEmailSavedToDraft: true,
    isDraftUpdate: false,
    defaultToMail: [],
    draftData: {
      id: null,
      receiver: [],
      subject: "",
      body: "",
    },
  },
  reducers: {
    setFullComposeModel: (state, action) => {
      state.fullComposeModel = action.payload;
    },
    setDefaultToMail: (state, action) => {
      state.defaultToMail = action.payload;
    },
    setOpenComposeModel: (state, action) => {
      state.openComposeModel = action.payload;
    },
    setComposeModel: (state, action) => {
      state.composeModel = action.payload;
    },
    setDraftData(state, action) {
      state.draftData = action.payload;
    },
    setIsEmailSavedToDraft(state, action) {
      state.isEmailSavedToDraft = action.payload;
    },
    setIsDraftUpdate(state, action) {
      state.isDraftUpdate = action.payload
    }
  },
});
export const {
  setFullComposeModel,
  setOpenComposeModel,
  setComposeModel,
  setDraftData,
  setDefaultToMail,
  setEmptyDraftData,
  setIsEmailSavedToDraft,
  setIsDraftUpdate
} = slice.actions;

export const selectDefaultToMail = (state) => state.ComposeModelReducer.defaultToMail;
export const selectComposeModel = (state) => state.ComposeModelReducer.composeModel;
export const selectOpenComposeModel = (state) => state.ComposeModelReducer.openComposeModel;
export const selectFullComposeModel = (state) => state.ComposeModelReducer.fullComposeModel;
export const selectDraftData = (state) => state.ComposeModelReducer.draftData;

export const selectedEmailSavedToDraft = (state) => state.ComposeModelReducer.isEmailSavedToDraft;
export const selectedIsDraftUpdateSelector = (state) => state.ComposeModelReducer.isDraftUpdate;

export default slice.reducer;
