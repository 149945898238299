import { useSelector } from "react-redux";
import { useGetUserDataQuery } from "../../redux/storeApis";
import { selectedLoginUser } from "../../redux/UserReducer";
import { useEffect } from "react";
import { setLocalStorage } from "../../components/localStorage";
import { KEYS } from "../../constants/Index";

export function useUserManager() {
  const {
    data: userData,
    isLoading: isLoadingUser,
    isSuccess: isSuccessUser,
  } = useGetUserDataQuery();
  // const meId = userData?.data?.user?.id;
  const userDetails = userData?.data?.user;
  const userName = userDetails?.name;
  const userEmail = userDetails?.email;
  const userMobile = userDetails?.mobile;

  const isLoggedInUser = useSelector(selectedLoginUser);

  // useEffect(() => {
  //   setLocalStorage(KEYS.meId, meId);
  // }, [meId]);

  return {
    userDetails,
    userName,
    userEmail,
    userMobile,
    isLoggedInUser,

    isLoadingUser,
    isSuccessUser,
  };
}
