import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import {
  useAddEmailSettingMutation,
  useGetEmailSettingQuery,
  useUpdateEmailSettingMutation,
} from "../../../redux/storeApis";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";
import DZModal from "./DZModal";
import { CrossIcon } from "../../../icons/generalIcons/Icons";
const AddUpdateEmailSetting = ({
  cancel,
  title = "Add Your Email Credentials",
  emailSettingModal,
  setEmailSettingModal,
}) => {
  const { data: emailSetting, isLoading: isLoadingEmailSetting } = useGetEmailSettingQuery();
  const [addEmailSetting, { isLoading: isLoadingCreateEmailSetting }] = useAddEmailSettingMutation();
  const [updateEmailSetting, { isLoading: isLoadingUpdateEmailSetting }] = useUpdateEmailSettingMutation();

  console.log('emailSetting', emailSetting)

  const { fnShowSnackBar } = useSnackBarManager();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [inputData, setInputData] = useState({
    server_type: "",
    server_name: "",
    port: "993",
    encryption: "tls",
    username: "",
    password: "",
  });

  const emailCredential = emailSetting?.data?.email_credential;

  useEffect(() => {
    setInputData({
      server_type: emailCredential?.server_type,
      server_name: emailCredential?.server_name,
      port: emailCredential?.port,
      encryption: emailCredential?.encryption,
      username: emailCredential?.username,
      password: emailCredential?.password,
    });
  }, [emailSetting]);

  const getDomainFromEmail = (email) => {
    const atIndex = email?.indexOf("@");
    if (atIndex !== -1) {
      return email?.slice(atIndex + 1);
    }
    return "";
  };

  useEffect(() => {
    if (!inputData?.server_name) {
      setInputData((pre) => ({
        ...pre,
        server_name: getDomainFromEmail(inputData?.username),
      }));
    }
    if (!inputData?.port) {
      setInputData((pre) => ({ ...pre, port: "993" }));
    }
    if (!inputData?.server_type) {
      setInputData((pre) => ({ ...pre, server_type: "imap" }));
    }
  }, [
    inputData?.server_name,
    inputData?.username,
    inputData?.port,
    inputData?.server_type,
  ]);

  const fnOnChange = (event) => {
    const { name, value } = event.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
  };

  const fnEmailSetting = () => {
    const data = {
      server_type: "imap",
      server_name: inputData?.server_name,
      port: inputData?.port,
      encryption: inputData?.encryption,
      username: inputData?.username,
      password: inputData?.password,
    };
    if (
      inputData?.server_name &&
      inputData?.encryption &&
      inputData.username &&
      (inputData.password || emailSetting) &&
      inputData?.port
    ) {
      if (emailSetting) {
        updateEmailSetting({
          data,
          id: emailSetting?.data?.email_credential?.id,
        })
          .unwrap()
          .then((payload) => {
            if (payload.success) {
              setEmailSettingModal(false);
              fnShowSnackBar("Setting Updated successfully!");
              setInputData(null);
            }
          })
          .catch((error) => {
            console.log(error, "error");
            fnShowSnackBar(
              error?.data?.message || "Something went wrong, please try again!",
              true
            );
          });
      } else {
        addEmailSetting(data)
          .unwrap()
          .then((payload) => {
            if (payload.success) {
              setEmailSettingModal(false);
              fnShowSnackBar("Setting Updated successfully!");
              setInputData(null);
            }
          })
          .catch((error) => {
            console.log(error, "error");
            fnShowSnackBar(
              error?.data?.message || "Something went wrong, please try again!",
              true
            );
          });
      }
    } else {
      fnShowSnackBar("Please fill the required fields!");
    }
  };
  const { handleKeyDown } = useEnterKeySubmit(fnEmailSetting);
  return (
    emailSettingModal && (
      <DZModal>
        {
          emailCredential?.google_id ? 
            <div className="flex flex-col gap-[6px]" >
              <CrossIcon onClick={cancel} className={'self-end'} />
              <h1 className="font-semibold" >Email Setting</h1>
              <p>There are no email settings available as you have logged in with a Google account.</p>
            </div>
            : <>
              <DZText
                normal
                children={
                  isLoadingEmailSetting
                    ? "Loading..."
                    : emailSetting
                      ? "Update a login"
                      : title
                }
                className={"text-112D4E"}
              />
              <InputField
                name={"username"}
                value={inputData?.username}
                onChange={fnOnChange}
                placeholder="Email e.g test@gmail.com"
                className={styles.input}
                onKeyDown={handleKeyDown}
                autoComplete={"off"}
              />
              <InputField
                name={"password"}
                value={inputData?.password}
                onChange={fnOnChange}
                placeholder="Password"
                type={"password"}
                className={styles.input}
                onKeyDown={handleKeyDown}
                autoComplete="new-password"
              />
              <div
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="flex items-center justify-end w-full gap-[8px]   "
              >
                <i
                  class={`ri-arrow-${showAdvanced ? "down-s" : "up-s"
                    }-line text-[24px] text-[#112D4E] cursor-pointer `}
                ></i>
                <span className={styles.advanced}>Advanced Setting</span>
              </div>
              {showAdvanced && (
                <div className={styles.advancedContainer}>
                  <InputField
                    name={"server_name"}
                    value={inputData?.server_name}
                    onChange={fnOnChange}
                    placeholder="Server Name e.g gmail.com"
                    className={styles.input}
                    onKeyDown={handleKeyDown}
                  />
                  <InputField
                    name={"port"}
                    value={inputData?.port}
                    onChange={fnOnChange}
                    placeholder="Port e.g 993"
                    className={styles.input}
                    onKeyDown={handleKeyDown}
                  />
                  <InputField
                    name={"encryption"}
                    value={inputData?.encryption}
                    onChange={fnOnChange}
                    placeholder="Encryption SSL/TLS"
                    className={styles.input}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              )}

              <div className="flex items-center gap-[14px]">
                <DZBtn
                  onClick={() => {
                    fnEmailSetting();
                  }}
                  solid
                  className={styles.addBtn}
                >
                  {isLoadingUpdateEmailSetting ||
                    isLoadingCreateEmailSetting ||
                    isLoadingEmailSetting
                    ? "Loading..."
                    : emailSetting
                      ? "Update"
                      : "Add"}
                </DZBtn>
                <DZBtn onClick={cancel} className={styles.cancelBtn}>
                  {"Cancel"}
                </DZBtn>
              </div>
            </>
        }
      </DZModal>
    )
  );
};
const styles = {
  container:
    "modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[12px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]",
  advancedContainer: "flex flex-col gap-[12px] w-full",
  input: "w-full placeholder:text-3F72AF",
  addBtn: "text-white rounded-[10px] cursor-pointer min-w-[104px]",
  advanced: "text-112D4E cursor-pointer decoration-[#3F72AF]",
  cancelBtn: "rounded-[10px] text-112D4E cursor-pointer bg-white shadow",
};

export default AddUpdateEmailSetting;
