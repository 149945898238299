import React from 'react'
import { SearchIcon } from '../../../icons/generalIcons/Icons'
import { useSearchManager } from '../../../lib/customHooks/useSearchManager';
import DZBtn from '../buttons/DZBtn';

const DZSearchBar = ({ onChange, onKeyDown, className, onClick, inputClassName }) => {

    const { searchLoader } = useSearchManager();

    return (
        <div className="w-full">

            <div className="flex items-center justify-between bg-[white] rounded-[25px] drop-shadow mx-1 px-[8px] w-full max-w-[600px]">
                <input
                    type="text"
                    placeholder="Search"
                    className="text-[16px] w-[full] cursor-text p-[12px] rounded-[25px] ml-[5px] outline-none placeholder:text-[#112D4E] text-112D4E"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
                <DZBtn onClick={onClick} solid
                    className={'rounded-full !px-[12px]'}
                    textStyle={'hidden'}
                    btnIcon=
                    {searchLoader
                        ? <img className="rounded-full w-[16.5px] animate-spin" src={'/circle.png'} />
                        : <SearchIcon className="cursor-pointer" iconColor="#f9F7F7" />
                    }
                />
            </div>
        </div>
    )
}

export default DZSearchBar