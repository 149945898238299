import React, { useEffect, useState } from 'react';
import SelectDropDown from '../../components/shared/dropDowns/SelectDropDown';
import ViewDropDownItem from '../../components/views/ViewDropDownItem';
import { BankIcon, CreaditCardIcon, CrossIcon, DeleteIcon, EditIcon, IDCardIcon, LicenseIcon, PassPortIcon, SearchIcon, SecurityIcon, UserIcon } from '../../icons/generalIcons/Icons';
import { useHandleModalManager } from '../../lib/customHooks/useHandleModalManager';
import AddUpdateIdsModal from '../../components/shared/modals/AddUpdateIdsModal';
import DZText from '../../components/shared/text/DZText';
import { useDeleteMultipleIdsMutation, useGetIdsQuery } from '../../redux/passwordManagerApis';
import CheckInput from '../../components/shared/checkInput/CheckInput';
import { Tooltip } from '@mui/material';
import DeleteModal from '../../components/shared/modals/DeleteModal';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { Config, ID_CARD_TYPES } from '../../constants/Index';
import { EmptyLoader, SimpleLoader } from '../../components/loader/Loaders';
import { useSearchManager } from '../../lib/customHooks/useSearchManager';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { useSelector } from 'react-redux';
import { hideSelectedIdModal, selectedIdModalSelector } from '../../redux/PasswordManagerReducer';
import { useDispatch } from 'react-redux';
import DZSearchBar from '../../components/shared/searchbar/DZSearchbar';

const PasswordManagerIDs = () => {

  const dispatch = useDispatch();

  const { data: idCardsData, isLoading: isLoadingIdCards } = useGetIdsQuery();
  const [deleteMultipleIdCards, { isLoading: isLoadingDeleteIdCards }] = useDeleteMultipleIdsMutation();

  const { searchLoader, fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterIds } = useSearchManager();

  const isShowIdModal = useSelector(selectedIdModalSelector);

  const [idModal, setIdModal] = useState(false);
  const [idCardModal, setIdCardModal] = useState({ add: null, update: null, type: null, });
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCardDetail, setSelectedCardDetail] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [selectedIdCards, setSelectedIdCards] = useState([]);
  const isExistSelectedCards = selectedIdCards?.length > 0;
  const { modalRef } = useHandleModalManager({ modal: idModal, setModal: setIdModal, closeModal: () => dispatch(hideSelectedIdModal()) });
  const { fnShowSnackBar } = useSnackBarManager();

  const filteredIds = fnFilterIds(idCardsData);

  const { ID_Card, Driver_License, Social_Security, PassPort } = ID_CARD_TYPES;

  const dropDownItemsData = [
    { title: 'ID Card', onClick: () => fnOnClickItem(ID_Card), icon: <CreaditCardIcon iconColor='#3F72AF' /> },
    { title: 'Social Security number', onClick: () => fnOnClickItem(Social_Security), icon: <SecurityIcon iconColor='#3F72AF' /> },
    { title: "Driver's license", onClick: () => fnOnClickItem(Driver_License), icon: <LicenseIcon iconColor='#3F72AF' /> },
    { title: 'Passport', onClick: () => fnOnClickItem(PassPort), icon: <PassPortIcon iconColor='#3F72AF' /> },
  ];

  const isExistIdCards = idCardsData?.length > 0;


  const fnOnClickItem = (type) => {
    setIdModal(false);
    setModalHeading(type);
    setIdCardModal({ ...idCardModal, add: true, type })
  };

  const fnDeleteCard = () => {
    const ids = selectedIdCards?.map((idCard) => idCard?.id);

    deleteMultipleIdCards({ ids }).unwrap().then((payload) => {
      if (payload?.success) {
        setSelectedIdCards([]);
        fnShowSnackBar('Deleted selected Items successfully!');
        setDeleteModal(false);

      }
    }).catch((error) => {
      fnShowSnackBar('Something went wrong, please try again!');
    });
  };

  const fnOnClickCheckInput = (e, detail) => {
    if (selectedIdCards.some((idCard) => idCard?.id === detail?.id)) {
      setSelectedIdCards(selectedIdCards.filter((idCard) => idCard?.id !== detail?.id));
    } else {
      setSelectedIdCards([...selectedIdCards, detail]);
    }
  };

  const fnClickOnEditIcon = (card) => {
    setSelectedCardDetail(card);
    setIdCardModal({ ...idCardModal, update: true, type: card?.type });
  };

  const fnEmptyData = () => {
    setSelectedCardDetail(false);
  };


  return (
    <div className="flex flex-wrap gap-[24px] h-full overflow-y-scroll last:pb-[56px]">

      {isShowIdModal && (
        <SelectDropDown dropDownRef={modalRef} className={'absolute top-[206px] shadow left-[16px] min-w-[302px] z-[2] max-lg:top-[80px] max-lg:left-[84px] max-sm:top-auto max-sm:bottom-[80px] max-sm:left-[8%]'}>
          {dropDownItemsData?.map((data, i) => (
            <ViewDropDownItem key={i} title={data?.title} onClick={data.onClick} icon={data.icon} />
          ))}
        </SelectDropDown>
      )}

      <DZText className={'text-[#112D4E] w-full'} children={"ID's"} normal />

      <DZSearchBar
        onChange={(event) => fnOnSearchInputChange(event)}
        onKeyDown={fnOnEnter}
        onClick={fnOnSearch}
      />

      {isExistSelectedCards && (
        <div className={`flex items-center justify-between w-full h-[46px]`}>
          <div className="flex items-center gap-[12px]">
            <span onClick={() => setSelectedIdCards([])} className="bg-[#112D4E] p-[6px] rounded-full cursor-pointer">
              <CrossIcon iconColor="#F9F7F7" className={"w-[20px] h-[20px] cursor-pointer"} />
            </span>
          </div>
          <div className="flex items-center gap-[20px]">
            <Tooltip arrow title="Delete">
              <span onClick={() => setDeleteModal(true)} className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                <DeleteIcon className={"w-[16px] h-[16px] cursor-pointer"} />
              </span>
            </Tooltip>
          </div>
        </div>
      )}


      {isLoadingIdCards ? <SimpleLoader /> :
        isExistIdCards ? <div className="w-full grid grid-cols-6 max-2xl:grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[450px]:grid-cols-1 gap-[24px]">
          {filteredIds?.map((card) => {
            const cardType = card?.type;

            const isIDCard = cardType == ID_CARD_TYPES.ID_Card;
            const isSocialSecurity = cardType == ID_CARD_TYPES.Social_Security;
            const isDriverLicense = cardType == ID_CARD_TYPES.Driver_License;
            const isPassport = cardType == ID_CARD_TYPES.PassPort;

            return (
              <div key={card.id} className="relative group flex flex-col items-center gap-[24px] bg-[#F7F9F9] p-[12px] pt-[24px] max-w-full">
                {/* <img src={`${Config.baseFileUrl}${card?.file}`} height={"200px"} width={'400px'} /> */}
                <span onClick={(e) => fnOnClickCheckInput(e, card)}>
                  <CheckInput active={selectedIdCards?.some((idCard) => idCard?.id === card?.id)} className={'!absolute top-[10px] left-[10px]'} />
                </span>

                {isIDCard ? <CreaditCardIcon className={styles.card_icon} />
                  : isSocialSecurity ? <SecurityIcon className={styles.card_icon} />
                    : isDriverLicense ? <LicenseIcon className={styles.card_icon} />
                      : isPassport ? <PassPortIcon className={styles.card_icon} />
                        : <UserIcon className={styles.card_icon} />
                }

                <div className="flex flex-col gap-[10px] w-full">
                  <span className="truncate w-[80%] text-[#3F72AF]">{card?.name}</span>
                  <span className="truncate w-full text-[#3F72AF]">{card?.number}</span>
                </div>
                <div className="absolute flex z-1 flex-col items-center gap-[10px] right-[10px] bottom-[15px] opacity-0 group-hover:opacity-100 transition">
                  {/* <DeleteIcon onClick={() => { setSelectedIdCards([card]); setDeleteModal(true); }} iconColor='#3F72AF' className={'cursor-pointer'} /> */}
                  <EditIcon onClick={() => { fnClickOnEditIcon(card) }} iconColor='#3F72AF' className={'cursor-pointer'} />
                </div>
              </div>
            )
          })}
        </div>
          : <EmptyLoader />
      }


      {deleteModal && (<DeleteModal onClickYes={() => fnDeleteCard()} onClickNo={() => setDeleteModal(false)} okTitle={isLoadingDeleteIdCards ? 'Loading' : 'Yes'} />)}

      {(idCardModal.add || idCardModal.update) &&
        <AddUpdateIdsModal
          modal={idCardModal}
          title={modalHeading}
          setModal={setIdCardModal}
          type={idCardModal.type}
          cancel={() => setIdCardModal(false)}
          isUpdate={idCardModal.update}
          idCardData={idCardModal.update && selectedCardDetail}
          fnEmptyData={fnEmptyData}
        />
      }

      {/* {socialSecurityNumber && <AddSocialSecurityNumber modal={socialSecurityNumber} setModal={setSocialSecurityNumber} cancel={() => setSocialSecurityNumber(false)} ok={() => setSocialSecurityNumber(false)} />}
      {driverLicense && <AddDriverLicense modal={driverLicense} setModal={setDriverLicense} cancel={() => setDriverLicense(false)} ok={() => setDriverLicense(false)} />}
      {passport && <AddPassport modal={passport} setModal={setPassport} cancel={() => setPassport(false)} ok={() => setPassport(false)} />}
      {taxNumber && <AddTaxNumber modal={taxNumber} setModal={setTaxNumber} cancel={() => setTaxNumber(false)} ok={() => setTaxNumber(false)} />} */}
    </div>
  );
};

const styles = {
  card_icon: 'h-[42px] w-[42px]'
}

export default PasswordManagerIDs;
