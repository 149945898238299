import React, { useEffect, useState } from "react";
import {
  useAddEmailSettingMutation,
  useGetEmailSettingQuery,
  useUpdateEmailSettingMutation,
} from "../../redux/storeApis";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import useEnterKeySubmit from "../../lib/useEnterKeySubmit";

function EmailSettings() { 
  const { data: emailSetting, isLoading: isLoadingEmailSetting } = useGetEmailSettingQuery();
  const [updateEmailSetting, { isLoading: isLoadingUpdateEmailSetting }] = useUpdateEmailSettingMutation();

  const { fnShowSnackBar } = useSnackBarManager();

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [inputData, setInputData] = useState({
    server_type: "",
    server_name: "",
    port: "993",
    encryption: "tls",
    username: "",
    password: "",
  });

  useEffect(() => {
    setInputData({
      server_type: emailSetting?.data?.email_credential?.server_type,
      server_name: emailSetting?.data?.email_credential?.server_name,
      port: emailSetting?.data?.email_credential?.port,
      encryption: emailSetting?.data?.email_credential?.encryption,
      username: emailSetting?.data?.email_credential?.username,
    });
  }, [emailSetting]);

  const getDomainFromEmail = (email) => {
    const atIndex = email?.indexOf("@");
    if (atIndex !== -1) {
      return email?.slice(atIndex + 1);
    }
    return "";
  };
  
  useEffect(() => {
    setInputData((pre) => ({
      ...pre,
      server_name: getDomainFromEmail(inputData?.username),
    }));
  }, [inputData?.username]);

  useEffect(() => {
    if (!inputData?.server_name) {
      setInputData((pre) => ({
        ...pre,
        server_name: getDomainFromEmail(inputData?.username),
      }));
    }
    if (!inputData?.port) {
      setInputData((pre) => ({ ...pre, port: "993" }));
    }
    if (!inputData?.server_type) {
      setInputData((pre) => ({ ...pre, server_type: "imap" }));
    }
  }, [
    inputData?.server_name,
    inputData?.username,
    inputData?.port,
    inputData?.server_type,
  ]);

  const fnOnChange = (event) => {
    const { name, value } = event.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
  };

  const fnEmailSetting = () => {
    const data = {
      server_type: "imap",
      server_name: inputData?.server_name,
      port: inputData?.port,
      encryption: inputData?.encryption,
      username: inputData?.username,
      password: inputData?.password,
    };
    if (
      inputData?.server_name &&
      inputData?.encryption &&
      inputData.username &&
      (inputData.password || emailSetting) &&
      inputData?.port
    ) {
      if (emailSetting) {
        updateEmailSetting({
          data,
          id: emailSetting?.data?.email_credential?.id,
        })
          .unwrap()
          .then((payload) => {
            if (payload.success) {
              setEmailSettingModal(false);
              fnShowSnackBar("Setting Updated successfully!");
              setInputData(null);
            }
          })
          .catch((error) => {
            fnShowSnackBar(
              error?.data?.message || "Something went wrong, please try again!",
              true
            );
          });
      }
    } else {
      fnShowSnackBar("Please fill the required fields!");
    }
  };

  const {handleKeyDown} = useEnterKeySubmit(fnEmailSetting);

  const fnResetSetting = () => {
    setInputData({
      server_type: emailSetting?.data?.email_credential?.server_type,
      server_name: emailSetting?.data?.email_credential?.server_name,
      port: emailSetting?.data?.email_credential?.port || "993",
      encryption: emailSetting?.data?.email_credential?.encryption || "tls",
      username: emailSetting?.data?.email_credential?.username,
    });
  };

  return (
    <div className="flex flex-col gap-[20px] rounded-[20px] bg-[#F9F7F7] p-[30px]">
      <h1 className="text-[36px] font-[600] text-[#112D4E]">Update Login</h1>
      <p className="text-[#3F72AF]">
        Easily update your login information to ensure your account remains
        secure and accessible. Enter your new credentials in the fields
        provided, and confirm your changes with a single click. Keeping your
        login details up-to-date has never been simpler, giving you peace of
        mind and uninterrupted access to your account.
      </p>
      <input
        name={"username"}
        value={inputData?.username}
        onChange={fnOnChange}
        placeholder="Email e.g test@gmail.com"
        onKeyDown={handleKeyDown}
        className="rounded-xl px-[20px] py-[15px] text-[#112D4E] outline-none placeholder:text-[#3F72AF]"
      />
      <input
        name={"password"}
        value={inputData?.password}
        onChange={fnOnChange}
        placeholder="Password"
        type={"password"}
        onKeyDown={handleKeyDown}
        className="rounded-xl px-[20px] py-[15px] text-[#112D4E] outline-none placeholder:text-[#3F72AF]"
      />
      <div
        onClick={() => setShowAdvanced(!showAdvanced)}
        className="flex items-center gap-[12px]"
      >
        <span className="text-[#112D4E] cursor-pointer">Advanced Settings</span>
        <svg
          className={`cursor-pointer transition-all ${showAdvanced && "rotate-180"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
        >
          <path
            d="M1 1L6.29289 6.29289C6.62623 6.62623 6.79289 6.79289 7 6.79289C7.20711 6.79289 7.37377 6.62623 7.70711 6.29289L13 1"
            stroke="#112D4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {showAdvanced && (
        <div className="flex flex-col gap-[12px] w-full">
          <input
            name={"server_name"}
            value={inputData?.server_name}
            onChange={fnOnChange}
            onKeyDown={handleKeyDown}
            placeholder="Server Name e.g gmail.com"
            className="rounded-xl px-[20px] py-[15px] text-[#112D4E] outline-none placeholder:text-[#3F72AF]"
          />
          <input
            name={"port"}
            value={inputData?.port}
            onChange={fnOnChange}
            placeholder="Port e.g 993"
            onKeyDown={handleKeyDown}
            className="rounded-xl px-[20px] py-[15px] text-[#112D4E] outline-none placeholder:text-[#3F72AF]"
          />
          <input
            name={"encryption"}
            value={inputData?.encryption}
            onChange={fnOnChange}
            placeholder="Encryption SSL/TLS"
            className="rounded-xl px-[20px] py-[15px] text-[#112D4E] outline-none placeholder:text-[#3F72AF]"
          />
        </div>
      )}
      <div className="flex justify-center  gap-[20px]">
        <button
          onClick={fnEmailSetting}
          className="rounded-xl bg-[#112D4E] px-[20px] py-[12px] text-[#F9F7F7] outline-none flex items-center justify-center"
        >
          {isLoadingUpdateEmailSetting || isLoadingEmailSetting
            ? "Loading..."
            : "Update"}
        </button>
        <button
          onClick={fnResetSetting}
          className="rounded-xl bg-[#FFF] shadow px-[20px] py-[12px] text-[#112D4E] outline-none flex items-center justify-center"
        >
          Reset Setting
        </button>
      </div>
    </div>
  );
}
export default EmailSettings;