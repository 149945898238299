import React, { useState } from "react";
import { Config, KEYS } from "../../constants/Index";
import { getLocalStorage, setLocalStorage } from "../../components/localStorage";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useGoogleLogin } from '@react-oauth/google';
import Google from "../../icons/productIcon/Google";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import PP2Service from "../../services";
import useAllAccountData from "../../lib/useAllAccountData";
import { setSelectedUserAccounts } from "../../redux/UserAccountsReducer";

const GoogleLoginButton = ({ onSuccess, onFailure }) => {

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fnShowSnackBar } = useSnackBarManager();
  const { handleSelectedEmail } = useAllAccountData();

  const alreadyLoggedInEmail = getLocalStorage(KEYS.emailId);

  const handleGoogleSignIn = async(codeResponse) => {
    setLoader(true);
    try {
      const response = await fetch(`${Config.serverApiUrl}google-login-web`, {
        method: 'POST', headers: { 'Content-Type': 'application/json'}, body: JSON.stringify({ code : codeResponse?.code }),
      });

      if (response?.ok) {
        const data = await response?.json();
        const fetchUserToken = data?.data?.access_token; 

        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          setLocalStorage(KEYS.meId, response?.data?.userId);

          dispatch(setSelectedLoginUser(fetchUserToken));

          const fetchUserAccounts = await PP2Service.fetch({ url: "user-accounts", token: fetchUserToken,  });
          const userAllAccounts = await fetchUserAccounts?.data?.accounts;
          
          dispatch(setSelectedUserAccounts(userAllAccounts));
          let userAccount = userAllAccounts[0];

          fnShowSnackBar("User successfully logged in!");

          if (userAllAccounts?.length > 0) {
            setTimeout(() => {
              handleSelectedEmail({ emailId : userAccount?.id, email : userAccount?.email, navigateToDashBoard : true});
              setLoader(false);
            }, 2000);
          } else {
            navigate(ROUTES.createAccount);
            setLoader(false);
          }
        }
      }
    } catch (error) {
      fnShowSnackBar("some error occurred to google login");
    }
  };

  // const handleGoogleSignIn = async (credentialResponse) => {
  //   const credential = credentialResponse?.credential;
  //   const payload = decodeJWT(credential);
  //   try {
  //     const response = await fetch(`${Config.serverUrl}api/google-login-web`, {
  //       method: 'POST', headers: { 'Content-Type': 'application/json'}, body: JSON.stringify({ data: payload }),
  //     });
  //     if (response.ok) {
  //       console.table(response);
  //       const data = await response.json();
  //       const fetchUserToken = data?.data?.access_token; 
  //       if (fetchUserToken) {
  //         setLocalStorage(Config.userToken, fetchUserToken);
  //         dispatch(setSelectedLoginUser(fetchUserToken));
  //         // if(alreadyLoggedInEmail) { navigate(ROUTES.drive); window.location.reload(); } 
  //         // else { navigate(ROUTES.allAccounts); window.location.reload();}
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Google Sign-In was unsuccessful:', error);
  //     if (onFailure) onFailure(error);
  //   }
  // };

  // const signInWithGoogle = async (data) => {
  //   try {
  //     const response = await fetch(`${Config.serverUrl}api/google-login-web`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ data }),
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data?.data?.access_token) {
  //         setLocalStorage(Config.userToken, data?.data?.access_token);
  //         navigate('/');
  //       }
  //     } else {
  //       console.error("API request failed");
  //     }
  //   } catch (error) {
  //     console.error("Error signing in with Google: ", error);
  //   }
  // };

  // function decodeJWT(token) {
  //   const base64Url = token.split(".")[1];
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   const jsonPayload = decodeURIComponent(
  //     atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );
  //   return JSON.parse(jsonPayload);
  // }

  const handleGoogleSignInFailure = (error) => {
    console.error("Google Sign-In was unsuccessful", error);
    if (onFailure) onFailure(error);
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleSignIn,
    onError: handleGoogleSignInFailure,
    accessType: 'offline',
    prompt: 'consent',
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    redirect_uri: "https://jurri.danzeetech.com/inbox"
  });
  

  return (
    <div style={{width:'100%', margin:'auto'}} > 
      {/* <GoogleLogin style={{ width: '100%' }} onSuccess={handleGoogleSignIn} onFailure={handleGoogleSignInFailure} /> */}

      {/* Custom Button */}
      <span className="w-[100%] cursor-pointer border-[1px] py-[13px] flex justify-center items-center rounded-[10px] border-[#DBE2EF]" onClick={()=> login()} >  
        { loader ? 'Loading...' : <Google /> }
      </span>
    </div>
  );
};

export default GoogleLoginButton;




