import React, { useState, useRef } from "react";
import DZText from "../../shared/text/DZText";
import { DashboardIcon, ListIcon } from "../../../icons/generalIcons/Icons";
import useOutsideClick from "../../../lib/useOutsideClick";
import { useDispatch } from "react-redux";
import { selectIsCardSquare, setIsCardVertical,} from "../../../redux/CardGridReducer";
import { useSelector } from "react-redux";
import ArrowDown from "../../../icons/arrowIcons/ArrowDown";
import { selectItemTypeModel, setItemTypeModel,} from "../../../redux/DriveFilterReducer";
import { useLocation } from "react-router-dom";

const FilterBar = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const itemTypeModel = useSelector(selectItemTypeModel);
  const isCardSquare = useSelector(selectIsCardSquare);
  const itemTypeModelRef = useRef(null);

  const [viewItemTypeModel, setViewItemTypeModel] = useState(false);

  useOutsideClick(itemTypeModelRef, () => setViewItemTypeModel(false));

  const handleItemType = (value) => {
    dispatch(setItemTypeModel(value));
    setViewItemTypeModel(false);
  };

  if (!location?.pathname?.includes("/drive")) {
    return null;
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-[20px]">
        {/* <div className="flex items-center px-[24px] py-[6px] bg-DBE2EF rounded-full cursor-pointer">
          <DZText
            children={"Recent"}
            className={"text-112D4E cursor-pointer"}
          />
        </div> */}
      </div>
      <div className="flex items-center gap-[20px]">
        {location?.pathname?.includes("trash") ? null : (
          <span className=" relative">
            <div
              onClick={() => setViewItemTypeModel(!viewItemTypeModel)}
              className={
                "flex items-center gap-[6px] bg-112D4E rounded-full cursor-pointer group px-[24px] py-[6px]"
              }
            >
              <DZText
                children={itemTypeModel}
                className={"text-F9F7F7 cursor-pointer"}
              />
              <ArrowDown
                iconColor="#F9F7F7"
                className={"group-hover:animate-bounce cursor-pointer"}
              />
            </div>
            { viewItemTypeModel && <div
              ref={itemTypeModelRef}
              className={` p-[12px] flex flex-col gap-[10px] rounded-[10px] bg-[#FFF] right-0 absolute top-[110%] z-[1] shadow w-[160px] animation-DriveCardDropDownOpen ${
                viewItemTypeModel
                  ? " animation-DriveCardDropDownOpen"
                  : " animation-DriveCardDropDownClose"
              }`}
            >
              <div className="flex items-center gap-[10px]">
                {itemTypeModel === "All" && (
                  <div className="w-[8px] h-[8px] bg-112D4E rounded-full"></div>
                )}
                <DZText
                  onClick={() => handleItemType("All")}
                  children={"All"}
                  className={`cursor-pointer ${
                    itemTypeModel === "All" ? " text-112D4E" : "text-3F72AF"
                  }`}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                {itemTypeModel === "Folders" && (
                  <div className="w-[8px] h-[8px] bg-112D4E rounded-full"></div>
                )}
                <DZText
                  onClick={() => handleItemType("Folders")}
                  children={"Folders"}
                  className={`cursor-pointer ${
                    itemTypeModel === "Folders" ? " text-112D4E" : "text-3F72AF"
                  }`}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                {itemTypeModel === "Files" && (
                  <div className="w-[8px] h-[8px] bg-112D4E rounded-full"></div>
                )}
                <DZText
                  onClick={() => handleItemType("Files")}
                  children={"Files"}
                  className={`cursor-pointer ${
                    itemTypeModel === "Files" ? "text-112D4E " : "text-3F72AF"
                  }`}
                />
              </div>
            </div>}
          </span>
        )}

        {/* <span
          onClick={() => dispatch(setIsCardVertical(true))}
          className={`p-[8px] cursor-pointer rounded-full  ${
            isCardSquare ? "bg-112D4E" : "bg-F7F9F9"
          } `}
        >
          <DashboardIcon
            className={"cursor-pointer"}
            iconColor={isCardSquare ? "#F7F9F9" : "#112D4E"}
          />
        </span>
        <span
          onClick={() => dispatch(setIsCardVertical(false))}
          className={`p-[8px] cursor-pointer rounded-full  ${
            isCardSquare ? "bg-F9F7F7" : "bg-112D4E"
          } `}
        >
          <ListIcon
            className={"cursor-pointer"}
            iconColor={isCardSquare ? "#112D4E" : "#F7F9F9"}
          />
        </span> */}
      </div>
    </div>
  );
};

export default FilterBar;
