import React, { useEffect, useRef, useState } from 'react'
import { CalendarIcon, DeleteIcon, MailIcon, OptionPlayIcon, ProfileIcon, SpeakersIcon } from '../../icons/svgs'
import { useGetDashBoardEmailsQuery } from '../../redux/storeApis'
import { SimpleLoader } from '../../components/loader/Loaders';
import { Config, KEYS } from '../../constants/Index';
import ProfileDropdown from '../../components/shared/dropDowns/ProfileDropDown';
import useAllAccountData from '../../lib/useAllAccountData';
import DZBtn from '../../components/shared/buttons/DZBtn';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';
import { getLocalStorage } from '../../components/localStorage';

const DashBoardPage = () => {

    const profileModal = useRef(null);
    const navigate = useNavigate();
    const selectedUserAccountEmail = getLocalStorage(KEYS.email);

    const { data: dashBoardEmailsData, isLoading: isLoadingDashBoardEmails } = useGetDashBoardEmailsQuery();
    const { accountsData, selectedEmail, handleSelectedEmail, isLoadingAllAccounts } = useAllAccountData();

    const [openProfileDrop, setOpenProfileDrop] = useState(false);

    const allDashBoardEmails = dashBoardEmailsData?.data?.received_emails;
    const isDashBoardEmailsExist = allDashBoardEmails?.length > 0;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileModal.current &&
                !profileModal.current.contains(event.target)
            ) {
                setOpenProfileDrop(false);
            }
        };

        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                setOpenProfileDrop(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        document.addEventListener("keydown", handleEscKey);

        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("keydown", handleEscKey);
        };
    }, []);

    function fnConvertDateFormat(dateString) {
        const date = new Date(dateString);

        if (isNaN(date?.getTime())) {
            return "Invalid date?";
        }

        const hours = date?.getHours();
        const minutes = date?.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const day = date?.getDate().toString().padStart(2, '0');
        const month = (date?.getMonth() + 1).toString().padStart(2, '0');
        const year = date?.getFullYear();

        return `${formattedHours}:${formattedMinutes} ${ampm} (${month}-${day}-${year})`;
    };

    const fnClickOnEmail = (email) => {
        const selectedAccount = accountsData?.find((account) => account?.email === email);
        handleSelectedEmail({ emailId: selectedAccount?.id, email: selectedAccount?.email, navigateToInbox: true })
    };

    return (
        <div className="flex h-full w-full items-start overflow-hidden">

            <div className="flex max-sm:flex-row sm:min-h-screen flex-col max-sm:justify-center items-center gap-[24px] bg-[#F9F7F7] p-[16px] max-sm:fixed max-sm:w-full max-sm:bottom-0 max-sm:left-0">
                <div className="flex items-center justify-center rounded-full bg-[#112D4E] p-[10px]">
                    <ProfileIcon />
                </div>
                <div className="flex items-center justify-center rounded-full bg-[#DBE2EF] p-[10px]">
                    <MailIcon />
                </div>
                <div className="flex items-center justify-center rounded-full bg-[#DBE2EF] p-[10px]">
                    <CalendarIcon />
                </div>
            </div>

            <div className='flex flex-col w-full h-full'>
                <div className="flex w-full items-end justify-end bg-[#F9F7F7] p-[16px]">
                    <div className="relative" ref={profileModal}>
                        <ProfileDropdown isShowEmailSettings={false} isShowDashBoard={false} openProfileDrop={openProfileDrop} profileModal={profileModal} />
                        <img src="./formsBack.png" className="h-[40px] object-cover rounded-full max-w-[40px] hover:border-2 hover:border-[#112D4E] border-2 border-transparent cursor-pointer transition" alt="" onClick={() => setOpenProfileDrop(!openProfileDrop)} />
                    </div>
                </div>

                {isLoadingDashBoardEmails ? <SimpleLoader containerClassName={'h-[90dvh]'} />
                    : <div className="flex h-full w-full flex-col pb-10">

                        <div className="flex max-h-full flex-1 w-full flex-col items-start gap-[24px] overflow-y-scroll p-[24px] max-sm:pb-[96px]">
                            {isDashBoardEmailsExist ?
                                <>
                                    {allDashBoardEmails?.map((email) => {

                                        const filterInboxMails = email?.emails?.filter((email) => {
                                            const fromEmail = Config.fnExtractEmail(email?.from);
                                            return fromEmail != selectedUserAccountEmail
                                        })?.slice(0, 3);

                                        return (
                                            <>
                                                <div className="flex w-full flex-col gap-[20px]">
                                                    <h2 className="text-[20px] font-[500] text-[#112D4E] cursor-pointer w-fit" onClick={() => fnClickOnEmail(email?.received_by)} >{email?.received_by}</h2>
                                                    <div className="flex w-full flex-col gap-[16px] sm:rounded-[20px] sm:bg-[#F9F7F7] sm:p-[16px]">
                                                        <div className="grid grid-cols-4 gap-[16px] p-[10px] max-sm:hidden">
                                                            <span className="text-[#112D4E] font-semibold"> From </span>
                                                            <span className="text-[#112D4E] font-semibold"> Subject </span>
                                                            <span className="text-[#112D4E] font-semibold"> Date </span>
                                                            <span className="text-end text-[#112D4E] font-semibold"> Actions </span>
                                                        </div>
                                                        {filterInboxMails?.map((data) => {
                                                            const message = data?.messages?.[0];
                                                            return (
                                                                <div className="flex flex-col sm:grid grid-cols-4 gap-[16px] p-[10px] max-sm:rounded-[16px] max-sm:bg-[#F9F7F7] max-sm:p-[16px]">
                                                                    <span className="text-[#112D4E] truncate w-full"> {Config.fnExtractEmail(data?.from)} </span>
                                                                    <span className="text-[#112D4E] truncate w-full"> {message?.subject} </span>
                                                                    <span className="text-[#112D4E] truncate w-full"> {fnConvertDateFormat(message?.date)} </span>
                                                                    <div className="flex items-center justify-end gap-[10px]">
                                                                        <div className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-[#112D4E] p-[6px]">
                                                                            <DeleteIcon />
                                                                        </div>
                                                                        <div className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-[#DBE2EF] p-[6px]">
                                                                            <SpeakersIcon />
                                                                        </div>
                                                                        <div className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-[#DBE2EF] p-[6px]">
                                                                            <OptionPlayIcon />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <hr className='border-[#eee] w-full' />
                                            </>
                                        )
                                    })}
                                </>
                                : <div className='flex items-center justify-center h-full w-full flex-col gap-3'>
                                    <span>You don't have any account, so you need to create account first</span>
                                    <DZBtn
                                        onClick={() => navigate(ROUTES.createAccount)}
                                        className={"rounded-[10px]"}
                                        textStyle={"text-[#F7F9F9]"}
                                        solid
                                    >
                                        Go To Create Account
                                    </DZBtn>
                                </div>
                            }
                        </div>

                    </div>
                }
            </div>

        </div>
    )
}

export default DashBoardPage