import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "./RouteConstants";
import { selectedLoginUser } from "../redux/UserReducer";

function ProtectedRoutes() {
  const loginUser = useSelector(selectedLoginUser);

  if (!loginUser) {
    return <Navigate to={ROUTES.home} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
