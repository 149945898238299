import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config, KEYS } from "../constants/Index";
import { getLocalStorage } from "../components/localStorage";

const TAG_TYPES = {
  drivesFolders: "drivesFolders",
  driveFolder: "driveFolder",
  trash: "trash",
  favorites: "favorites",
};

export const juriiDriveApi = createApi({
  reducerPath: "juriiDriveApi",
  baseQuery: fetchBaseQuery({
    baseUrl: Config.serverApiUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {

      const emailId = getLocalStorage(KEYS.emailId);
      const email = getLocalStorage(KEYS.email);

      headers.set("email_id", emailId);
      headers.set("email", email);
      
      const storedToken = localStorage.getItem(Config.userToken);
      if (storedToken && endpoint !== "refresh")
        headers.set("Authorization", `Bearer ${storedToken}`);
      return headers;
    },
  }),

  tagTypes: [ TAG_TYPES.drivesFolders, TAG_TYPES.driveFolder, TAG_TYPES.trash,  TAG_TYPES.favorites,  ],

  endpoints: (builder) => ({
    // Drive api
    getDriveFolders: builder.query({
      query: () => "drive/folders",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.drivesFolders],
    }),
    getDriveFolder: builder.query({
      query: (path) => `drive/folders/1?path=${path}`,
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.drivesFolders],
    }),
    addDriveFolder: builder.mutation({
      query: (body) => ({ url: "drive/folders", method: "POST", body }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    createFolder:  builder.mutation({
      query: (body) => ({ url: "drive/create-folder", method: "POST", body }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    addDriveFile: builder.mutation({
      query: (body) => ({ url: "drive/files", method: "POST", body }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    copyDriveFile: builder.mutation({
      query: (data) => ({
        url: "drive/copy-file",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    renameFile: builder.mutation({
      query: (data) => ({
        url: "drive/rename-file",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    copyDriveFolder: builder.mutation({
      query: (data) => ({
        url: "drive/copy-folder",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    moveDriveFile: builder.mutation({
      query: (data) => ({
        url: "drive/move-file",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    moveDriveFolder: builder.mutation({
      query: (data) => ({
        url: "drive/move-folder",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders],
    }),
    removeDriveFile: builder.mutation({
      query: (data) => ({
        url: `drive/files/1`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.trash],
    }),
    moveToTrashFile: builder.mutation({
      query: (data) => ({
        url: "drive/trashes",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.drivesFolders, TAG_TYPES.trash, TAG_TYPES.favorites],
    }),
    getTrashData: builder.query({
      query: () => "drive/trashes",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.trash],
    }),
 
    emptyTrash: builder.mutation({
      query: (data) => ({
        url: "drive/empty-trash",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [ TAG_TYPES.trash],
    }),
    addToStar: builder.mutation({
      query: (data) => ({
        url: "drive/favorites",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.favorites],
    }),
    removeFromStar: builder.mutation({
      query: (id) => ({
        url: `drive/favorites/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_TYPES.favorites],
    }),
    getStarData: builder.query({
      query: () => "drive/favorites",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.favorites],
    }),
    restoreFile: builder.mutation({
      query: (data) => ({
        url: "drive/restore",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.trash, TAG_TYPES.drivesFolders],
    }),
    downloadFile: builder.mutation({
      query: (data) => ({
        url: "drive/download-file",
        method: "POST", 
        body: data,
      }),
    }),
  }),
});

export const {
  useGetDriveFoldersQuery,
  useAddDriveFolderMutation,
  useGetDriveFolderQuery,
  useAddDriveFileMutation,
  useMoveToTrashFileMutation,
  useRemoveDriveFileMutation,
  useCopyDriveFileMutation,
  useCopyDriveFolderMutation,
  useMoveDriveFileMutation,
  useMoveDriveFolderMutation,
  useEmptyTrashMutation,
  useGetTrashDataQuery,
  useMoveToTrashFolderMutation,
  useGetStarDataQuery,
  useAddToStarMutation,
  useRemoveFromStarMutation,
  useRestoreFileMutation,
  useDownloadFileMutation,
  useRenameFileMutation,
  useCreateFolderMutation,
} = juriiDriveApi;
