import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config, KEYS } from "../constants/Index";
import { getLocalStorage } from "../components/localStorage";
const TAG_TYPES = {
  user: "user",
  draft: "draft",
  email: "email",
  favourites: "favourites",
  code: "code",
  user_accounts : 'user_accounts'
};
export const juriiApi = createApi({
  reducerPath: "juriiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: Config.serverApiUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const emailId = getLocalStorage(KEYS.emailId);
      const email = getLocalStorage(KEYS.email);

      headers.set("email_id", emailId);
      headers.set("email", email);

      const storedToken = localStorage.getItem(Config.userToken);
      if (storedToken && endpoint !== "refresh")
        headers.set("Authorization", `Bearer ${storedToken}`);
      return headers;
    },
  }),
  tagTypes: [
    TAG_TYPES.user,
    TAG_TYPES.draft,
    TAG_TYPES.email,
    TAG_TYPES.favourites,
    TAG_TYPES.user_accounts
  ],
  endpoints: (builder) => ({
    // Email api
    getDashBoardEmails : builder.query({ query : ()=> 'get-dashboard-emails'  }),

    getReceivedEmailList: builder.query({
      query: () => "email/received-emails",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.email],
    }),
    getSentEmailList: builder.query({
      query: () => "email/send-emails",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.email],
    }),
    getEmail: builder.query({ query: (emailId) => `email/email/${emailId}` }),
    sendEmail: builder.mutation({
      query: (data) => ({
        url: "email/send-email",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email],
    }),
    deleteEmail: builder.mutation({
      query: (emailId) => ({
        url: `email/emails/${emailId}`,
        method: "DELETE",
      }),
    }),
    getEmailSetting: builder.query({
      query: (id) => `email/email-settings/1`,
      providesTags: () => [TAG_TYPES.email],
    }),
    addEmailSetting: builder.mutation({
      query: (data) => ({
        url: "email/email-settings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email],
    }),
    googleAuth: builder.mutation({
      query: (data) => ({
        url: "google/auth",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email],
    }),
    updateEmailSetting: builder.mutation({
      query: ({ data, id }) => ({
        url: `email/email-settings/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email],
    }),
    // Drafts api
    getDraftList: builder.query({
      query: () => "email/drafts",
      providesTags: () => [TAG_TYPES.draft],
    }),
    getDraft: builder.query({ query: (draftId) => `email/drafts/${draftId}`, providesTags :()=> [TAG_TYPES.draft] }),
    createDraft: builder.mutation({
      query: (data) => ({ url: "email/drafts", method: "POST", body: data }),
      invalidatesTags: [TAG_TYPES.draft],
    }),
    updateDraft: builder.mutation({
      query: (data) => ({
        url: `email/drafts/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.draft],
    }),
    deleteDraft: builder.mutation({
      query: (draftId) => ({
        url: `email/drafts/${draftId}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_TYPES.draft],
    }),
    getFavourites: builder.query({
      query: () => "email/favourites",
      transformErrorResponse: (response) => response?.data,
      providesTags: () => [TAG_TYPES.email, TAG_TYPES.favourites],
    }),
    addFavourites: builder.mutation({
      query: (data) => ({
        url: "email/favourites",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email, TAG_TYPES.favourites],
    }),
    // Users APi

    getUserAccounts: builder.query({ query: () => "user-accounts", providesTags : ()=> [TAG_TYPES.user_accounts] }),
    deleteUserAccounts: builder.mutation({ query: (id) => ({ url: `user-accounts/${id}`, method: "DELETE" }),  invalidatesTags: [TAG_TYPES.user_accounts] }),
    createUserAccount: builder.mutation({  query: (data) => ({ url: "user-accounts", method: "POST", body: data }),  invalidatesTags: [TAG_TYPES.user, TAG_TYPES.user_accounts],  }),
    getHostName: builder.mutation({  query: (data) => ({ url: "get-email-config", method: "POST", body: data }),  invalidatesTags: [TAG_TYPES.user],  }),

    getUserData: builder.query({
      query: () => "me",
      providesTags: () => [TAG_TYPES.user],
    }), //current login user api
    updateUserData: builder.mutation({
      query: (data) => ({ url: `userupdate`, method: "POST", body: data }),
      invalidatesTags: [TAG_TYPES.user],
    }),
    addUser: builder.mutation({
      query: (data) => ({ url: `signup`, method: "POST", body: data }),
      invalidatesTags: [TAG_TYPES.user, TAG_TYPES.email, TAG_TYPES.draft],
    }),

    verifyEmailCode: builder.mutation({
      query: (data) => ({
        url: `verify-email-code`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.email, TAG_TYPES.code],
    }),

    LoginUser: builder.mutation({
      query: (data) => ({ url: `login`, method: "POST", body: data }),
      invalidatesTags: [TAG_TYPES.user, TAG_TYPES.email, TAG_TYPES.draft],
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `forgot-password`,
        method: "POST",
        body: { email },
      }),
    }),
    logout: builder.mutation({
      query: () => ({ url: "/logout", method: "POST" }),
      invalidatesTags: [TAG_TYPES.user, TAG_TYPES.email, TAG_TYPES.draft],
    }),
  }),
});
export const {
  useCreateUserAccountMutation,
  useGetHostNameMutation,
  useGetUserAccountsQuery,
  useGetDraftListQuery,
  useGetDraftQuery,
  useAddEmailSettingMutation,
  useGoogleAuthMutation,
  useUpdateEmailSettingMutation,
  useGetEmailSettingQuery,
  useCreateDraftMutation,
  useUpdateDraftMutation,
  useDeleteDraftMutation,
  useSendEmailMutation,
  useGetSentEmailListQuery,
  useGetReceivedEmailListQuery,
  useAddFavouritesMutation,
  useGetFavouritesQuery,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useAddUserMutation,
  useVerifyEmailCodeMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useDeleteUserAccountsMutation,
  useGetDashBoardEmailsQuery
} = juriiApi;
