import React, { useEffect, useState } from "react";
// import MailCard from "../../components/shared/mailCard/MailCard";
// import DropDownView from "../../components/shared/dropDowns/DropDownView.jsx";
// import { useGetEmailListQuery } from "../../redux/storeApis.js";
// import { useGetReceivedEmailListQuery } from "../../redux/storeApis";
import useInboxLogic from "../../lib/customHooks/useInboxLogic.js";
// import MailDetail from "../../components/shared/mailDetail/MailDetail.jsx";
// import useMailDetailLogic from "../../lib/customHooks/useMailDetailLogic.js";
// import OperationBar from "../../components/opreationBar/OperationBar.jsx";
// import DriveCard from '../../components/shared/cards/DriveCard.jsx'
import { Tooltip } from "@mui/material";
import CheckInput from "../../components/shared/checkInput/CheckInput.jsx";
// import {
//   ClockIcon,
//   CrossIcon,
//   ForwardIcon,
//   ReplyIcon,
//   StarIcon,
// } from "../../icons/generalIcons/Icons.jsx";
// import TimeDisplay from "../../components/ui/error/TimeDisplay.jsx";
// import DZText from "../../components/shared/text/DZText.jsx";
// import InputField from "../../components/shared/inputField/InputField.jsx";
// import DZBtn from "../../components/shared/buttons/DZBtn.jsx";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders.jsx";
import MailDetail from "../../components/shared/mailDetail/MailDetail.jsx";
import { ClockIcon, SearchIcon } from "../../icons/generalIcons/Icons.jsx";
import DZText from "../../components/shared/text/DZText.jsx";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../redux/LoadingReducer.js";
import { useSearchManager } from "../../lib/customHooks/useSearchManager.js";
import DZBtn from "../../components/shared/buttons/DZBtn.jsx";
import { Config, EMAIL_TYPE, KEYS } from "../../constants/Index.js";
import { getLocalStorage } from "../../components/localStorage/index.js";
import DZSearchBar from "../../components/shared/searchbar/DZSearchbar.jsx";
// import { ROUTES } from "../../reactRoute/RouteConstants.jsx";
// import { useNavigate } from "react-router-dom";
// import useAllAccountData from "../../lib/useAllAccountData.js";

const Inbox = () => {
  const { emails, isLoading: isLoadingEmails } = useInboxLogic();
  const isShowLoader = useSelector(selectIsLoading);
  const selectedUserAccountEmail = getLocalStorage(KEYS.email);

  const { searchLoader, fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterEmails } = useSearchManager();

  const filteredEmails = fnFilterEmails(emails, EMAIL_TYPE.inbox)?.filter((email) => {
    const fromEmail = Config.fnExtractEmail(email?.from);
    return fromEmail != selectedUserAccountEmail;
  });

  const isEmailsExist = filteredEmails?.length > 0;

  const [emailDetail, setEmailDetail] = useState(null);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(null);

  const fnOnClickEmail = (email, index) => {
    if (selectedEmailIndex != index) {
      setEmailDetail(email);
      setSelectedEmailIndex(index);
    } else {
      setEmailDetail(null);
      setSelectedEmailIndex(null);
    }
  };

  return (
    <div className={`w-full gap-[16px] h-full ${emailDetail ? 'grid grid-cols-2 max-lg:grid-cols-1' : 'flex items-start'}`}>
      <div className="flex flex-col items-center w-full gap-[16px] overflow-y-scroll h-[100dvh] max-sm:pb-[154px] pb-[90px]">

        <DZSearchBar
          onChange={(event) => fnOnSearchInputChange(event)}
          onKeyDown={fnOnEnter}
          onClick={fnOnSearch}
        />

        {isLoadingEmails || isShowLoader ? (
          <SimpleLoader />
        ) : isEmailsExist ? (filteredEmails?.slice()?.reverse()?.map((email, index) => {
          return (
            <div onClick={() => fnOnClickEmail(email, index)} className={`flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] max-sm:flex-col max-sm:items-start cursor-pointer ${selectedEmailIndex == index && "!bg-[#86BCFC30]"} `}>

              <Tooltip arrow title="Click to select it">

                <span onClick={(e) => { fnOnClickEmail(email, index); e.stopPropagation(); }} className="cursor-pointer" >
                  <CheckInput active={selectedEmailIndex == index} />
                </span>

              </Tooltip>

              <div className="flex flex-col gap-[4px] w-full cursor-pointer">

                <h1 className="cursor-pointer text-start">{Config.fnExtractEmail(email?.from)}</h1>
                <p className="cursor-pointer truncate max-xl:max-w-[240px]">{email?.subject}</p>

              </div>

              <div className="flex items-center gap-[8px]">
                <DZText children={Config.fnConvertEmailDate(email?.date)} small className={"text-end text-nowrap min-w-[40px]  text-112D4E cursor-pointer truncate w-full"} />
                <ClockIcon iconColor="#112D4E" className={"cursor-pointer"} />
              </div>


            </div>
          );
        })
        ) : (
          <EmptyLoader />
        )}
      </div>
      {selectedEmailIndex || emailDetail ? (
        <MailDetail
          receiver={Config.fnExtractEmail(emailDetail?.from)}
          subject={emailDetail?.subject}
          body={emailDetail?.body_html ? emailDetail?.body_html : emailDetail?.body_plain}
          date={emailDetail?.date}
          onClose={() => {
            setSelectedEmailIndex(null);
            setEmailDetail(null);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Inbox;
