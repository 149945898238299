import React, { useState } from "react";
import DZText from "../text/DZText";
import DZBtn from "../buttons/DZBtn";
import InputField from "../inputField/InputField";

import {
  MinusIcon,
  MaximizeIcon,
  CrossIcon,
  BrokenIcon,
  DeleteIcon,
} from "../../../icons/generalIcons/Icons";

import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import useComposeModel from "../../../lib/customHooks/useComposeModel";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import { selectDefaultToMail, setDefaultToMail, setDraftData } from "../../../redux/ComposeModelReducer";
import { useSelector } from "react-redux";

const ComposeModel = () => {
  const { fnShowSnackBar } = useSnackBarManager();
  const {
    composeModel,
    openComposeModel,
    fullComposeModel,
    setFullComposeModel,
    setOpenComposeModel,
    isLoadingSendMail,
    isLoading,
    handleInputChange,
    data,
    handleSubmit,
    setData,
    setIsDraftUpdate,
    handleCancel,
  } = useComposeModel();
  const dispatch = useDispatch();

  const [receiverCurrentValue, setReceiverCurrentValue] = useState("");

  const defaultToMail = useSelector(selectDefaultToMail);

  const handleAddReceiver = (newReceiver) => {
    let emailValidation = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newReceiver)) {
      fnShowSnackBar('please enter valid email!')
      emailValidation = false;
    }

    setData((prevState) => {
      let updatedReceiverData = [...prevState.receiver, newReceiver]
      if(!emailValidation) {
        updatedReceiverData =[...prevState.receiver]
      }
      const updatedData = {
        ...prevState,
        receiver: updatedReceiverData,
      };
      dispatch(setDraftData(updatedData));
      dispatch(setIsDraftUpdate(true));
      return updatedData;
    });

    return true;
  };

  const handleAddReceiverOnEnter = (e) => {
    if (e.code !== "Enter") return;
    if (handleAddReceiver(receiverCurrentValue)) {
      setReceiverCurrentValue("");
    }
  };

  const handleAddReceiverOnBlur = () => {
    if (receiverCurrentValue && handleAddReceiver(receiverCurrentValue)) {
      setReceiverCurrentValue("");
    }
  };


  // const handleAddReceiverOnEnter = (e) => {
  //   if (e.code !== "Enter") return;
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(receiverCurrentValue)) {
  //     fnShowSnackBar("Invalid Email!");
  //     return;
  //   }
  //   setData((prevState) => ({
  //     ...prevState,
  //     receiver: [...prevState.receiver, receiverCurrentValue],
  //   }));
  //   setReceiverCurrentValue("");
  //   // dispatch(setIsDraftUpdate(true))
  // };

  // const handleAddReceiverOnBlur = (e) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!receiverCurrentValue) {
  //     return;
  //   }
  //   if (!emailRegex.test(receiverCurrentValue)) {
  //     fnShowSnackBar("Invalid Email!");
  //     return;
  //   }

  //   setData((prevState) => ({
  //     ...prevState,
  //     receiver: [...prevState.receiver, receiverCurrentValue],
  //   }));
  //   if (receiverCurrentValue && handleAddReceiver(receiverCurrentValue)) {
  //     setReceiverCurrentValue("");
  //   }
  //   // dispatch(setIsDraftUpdate(true))
  // };

  const removeFromList = (emailToRemove) => {
    setData((prevState) => {

      let updatedReceivers = prevState?.receiver?.filter(email => email !== emailToRemove);

      if(emailToRemove == defaultToMail) {
        dispatch(setDefaultToMail([]));
        // updatedReceivers = updatedReceivers?.filter((email)=> email != defaultToMail);
      }

      const updatedData = { ...prevState, receiver: updatedReceivers };
      dispatch(setDraftData(updatedData));
      dispatch(setIsDraftUpdate(true));

      return updatedData;
    });
  };

  // const removeFromList = (index) => {
  //   const list = [...data.receiver];
  //   list.splice(index, 1);
  //   setData((prevState) => ({ ...prevState, receiver: list }));
  //   // dispatch(setIsDraftUpdate(true))
  // };

  return (
    <div
      className={`'
        max-sm:w-auto w-[496px] 
        p-[20px] 
        fixed bottom-0 left-auto max-sm:left-0 right-0 
        flex flex-col gap-[10px] 
        mx-[16px] 
        bg-F9F7F7 
        rounded-tl-[10px] rounded-tr-[10px] 
        transition-all duration 
        z-[1]
        shadow-[-16px_0px_103px_69px_rgba(10,38,71,0.15)] 
       ${openComposeModel
          ? " !max-sm:w-auto overflow-h sm:!h-[60px] sm:!w-[400px]"
          : ""
        }
       ${fullComposeModel
          ? "overflow-h !h-[88%] !left-0 !right-0 !w-auto !max-sm:w-[100%] z-10"
          : ""
        }
       ${composeModel ? "translate-y-[100%]" : ""}
       '`}
    >
      <div className="flex items-center justify-between w-full ">
        <DZText className="text-3F72AF" small={true} children={"New Message"} />
        <div className="flex items-center gap-[10px]">
          <Tooltip
            title={openComposeModel ? "Open" : "Minimize"}
            arrow
            placement="top"
          >
            <div
              onClick={() => {
                dispatch(setFullComposeModel(false));
                dispatch(setOpenComposeModel(!openComposeModel));
              }}
              className="max-sm:hidden"
            >
              {!openComposeModel && <MinusIcon className="cursor-pointer" />}
              {openComposeModel && (
                <CrossIcon className="rotate-45 cursor-pointer" />
              )}
            </div>
          </Tooltip>
          <Tooltip
            title={fullComposeModel ? "Restore" : " Maximize"}
            arrow
            placement="top"
          >
            <div
              onClick={() => {
                dispatch(setOpenComposeModel(false));
                dispatch(setFullComposeModel(!fullComposeModel));
              }}
              className={
                fullComposeModel
                  ? "transition rotate-180 max-sm:hidden"
                  : "transition rotate-0 max-sm:hidden"
              }
            >
              <MaximizeIcon className="cursor-pointer" />
            </div>
          </Tooltip>
          <Tooltip title="Close" arrow placement="top">
            <div
              onClick={() => {
                handleCancel();
              }}
            >
              <CrossIcon className="cursor-pointer" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="overflow-x-hidden max-h-[240px] flex items-center gap-[10px] flex-wrap px-[20px] py-[14px] cursor-text w-full rounded-[10px] bg-[#FFFFFF]">
        <input
          type="text"
          placeholder="To"
          className={
            "text-3F72AF w-full outline-none placeholder:capitalize text-3F72AF  placeholder:text-[#3F72AF]"
          }
          onBlur={handleAddReceiverOnBlur}
          onKeyDown={handleAddReceiverOnEnter}
          onChange={(e) => {
            setReceiverCurrentValue(e.target.value);
          }}
          value={receiverCurrentValue}
        />
        {data?.receiver?.length > 0 &&
          data?.receiver?.map((receiver) => (
            <span key={receiver} className="flex items-center w-fit gap-[10px] ">
              {receiver && <span
                className="text-3F72AF w-fit cursor-pointer py-[4px] px-[6px] rounded-[4px] bg-[#F9F7F7]"
                onClick={() => removeFromList(receiver)}
                // key={index}
              >
                <i class="ri-close-line"></i>
                {receiver}
              </span>}

            </span>
          ))}
      </div>
      <InputField
        id="subject"
        name="subject"
        type="text"
        placeholder="subject"
        value={data?.subject}
        onChange={handleInputChange}
        className={"placeholder:text-[#3F72AF] text-3F72AF"}
      />
      <textarea
        id="body"
        name="body"
        type="text"
        onChange={handleInputChange}
        value={data?.body}
        className="text-3F72AF min-h-[240px] px-[20px] resize-none h-full  py-[14px] cursor-text outline-none rounded-[10px] placeholder:text-[#3F72AF]"
        placeholder="Message"
      />
      <div className="flex items-center justify-between">
        <DZBtn
          className={"rounded-[10px]"}
          children={isLoading ? "Sending" : "Send"}
          solid
          onClick={handleSubmit}
          textStyle={"text-F9F7F7 cursor-poiner"}
        />
      </div>
    </div>
  );
};

export default ComposeModel;
