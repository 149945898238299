import React, { useEffect, useRef, useState } from "react";
import { useGetDriveFoldersQuery, useGetDriveFolderQuery, useMoveDriveFileMutation } from "../../../redux/juriiDriveApis";
import DZText from "../../shared/text/DZText";
import FolderIcon from "../../../icons/cardIcons/FolderIcon";
import DZBtn from "../../shared/buttons/DZBtn";
import ArrowDown from "../../../icons/arrowIcons/ArrowDown";
import { useDispatch } from "react-redux";
import { destinationSlugSelector, setDestinationSlug, sourceFileSelector, } from "../../../redux/MoveReducer";
import { useSelector } from "react-redux";
import useOutsideClick from "../../../lib/useOutsideClick";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";

const MoveModal = ({ setShowMoveModal, fileOrFolder }) => {

  const [driveFolders, setDriveFolders] = useState(null);
  const [folderSlug, setFolderSlug] = useState("");
  const moveRef = useRef(null);
  useOutsideClick(moveRef, () => setShowMoveModal(false));

  const sourceFile = useSelector(sourceFileSelector);
  const destinationSlug = useSelector(destinationSlugSelector);
  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();

  const { data: driveFoldersData, isLoading } = useGetDriveFoldersQuery();
  const { data: folderData, refetch } = useGetDriveFolderQuery(folderSlug);
  const [moveFile, { isLoading: isLoadingMoveFile, isSuccess: isSuccessMoveFile }] = useMoveDriveFileMutation();

  const fileOrFolderName = fileOrFolder?.name;

  const handleFolderDoubleClick = (folderName) => {
    const newFolderSlug = folderSlug ? `${folderSlug}/${folderName}` : folderName;
    fnSelectSlug(newFolderSlug);
    console.log("slug", newFolderSlug);
  };

  const fnNavigateBack = () => {
    if (folderSlug?.includes('/')) {
      const newFolderSlug = folderSlug?.split('/')?.slice(0, -1)?.join('/');
      fnSelectSlug(newFolderSlug);
      console.log("goback slug", newFolderSlug);
    } else {
      fnSelectSlug('');
    }
  };

  const fnSelectSlug = (slug) => {
    setFolderSlug(slug);
    dispatch(setDestinationSlug(slug));
    refetch();
  };

  useEffect(() => {
    setDriveFolders(folderData ? folderData?.data?.folders : driveFoldersData?.data?.folders);
  }, [driveFoldersData, folderData]);

  async function handleMove() {
    const response = await moveFile({ source_file: sourceFile, destination_file: destinationSlug });
    if (response?.data?.success) {
      setShowMoveModal(false);
      fnShowSnackBar('moved successfully!')
    }
  };


  return (
    <>
      <span onDoubleClick={(e)=> e.stopPropagation()} onClick={(e)=> e.stopPropagation()} className="fixed top-0 left-0 w-full h-full bg-black z-[1] opacity-30">

      </span>
      <div onClick={(e)=> e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()} className="max-lg:min-w-[420px] max-sm:min-w-[320px] max-sm:p-[16px] min-w-[614px] flex flex-col gap-[16px] modal-shadow z-[10] bg-F9F7F7 items-center justify-center p-[24px] fixed top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] rounded-[12px]">

        <DZText children={`Move this “${fileOrFolderName}” to file/folder`} className={"text-112D4E text-center text-[18px] font-medium"} />

        <DZText small children={"Select a location where you want the file/folder to move"} className={"text-3F72AF text-center"} />

        <div onClick={fnNavigateBack} className="flex items-center gap-[16px] cursor-pointer group w-full">

          <ArrowDown className={"rotate-90 w-[20px] h-[20px] cursor-pointer transition duration-500 group-hover:-translate-x-2"} />
          <DZText children={"Go Back"} className={"text-112D4E cursor-pointer"} />

        </div>

        <div onDoubleClick={(e) => e.stopPropagation()} className="grid max-lg:grid-cols-1 grid-cols-2 gap-[16px] p-[16px] bg-white rounded-[10px] w-full max-h-[400px] min-h-[100px] overflow-y-scroll">
          {isLoading
            ? "Loading..."
            : driveFolders?.length > 0
              ? driveFolders?.map((folder, index) => (
                <div key={folder?.id} className="flex items-center gap-[12px] p-[12px] bg-DBE2EF rounded-[10px] cursor-pointer" onClick={(e)=> e.stopPropagation()} onDoubleClick={(e) => { e.stopPropagation(); handleFolderDoubleClick(folder?.name); }}>
                  <FolderIcon className={"min-w-[46px] max-w-[46px] h-[46px] cursor-pointer"} />
                  <DZText children={folder?.name} small className={"text-3F72AF max-lg:w-[200px] truncate w-[160px] cursor-pointer text-white"} />
                </div>
              )) 
              : 'Move here'}
        </div>

        <div className="flex items-center gap-[24px]">
          <DZBtn
            children={isLoadingMoveFile ? "Loading..." : "Move"}
            solid
            textStyle={"cursor-pointer text-F9F7F7"}
            className={"rounded-[10px]"}
            onClick={(e) => {
              handleMove();
            }}
          />
          <DZBtn
            children={"Cancel"}
            outline
            textStyle={"cursor-pointer"}
            className={"rounded-[10px] bg-F9F7F7 shadow"}
            onClick={(e) => {
              setShowMoveModal(false);
            }}
          />
        </div>

      </div>
    </>
  );
};

export default MoveModal;
