import React from "react";

import { Navigate, useNavigate } from "react-router";
import { useUserManager } from "../../lib/customHooks/useUserManager";
import { removeLocalStorage } from "../../components/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { Config, KEYS } from "../../constants/Index";
import { ROUTES } from "../../reactRoute/RouteConstants";
import DZText from "../../components/shared/text/DZText";

import { LogoutIcon } from "../../icons/generalIcons/Icons"; 

import { juriiApi } from "../../redux/storeApis";
import { setSelectedUserAccounts } from "../../redux/UserAccountsReducer";

function Logout() {
  const { isLoggedInUser } = useUserManager();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fnLogoutUser = async () => {
    removeLocalStorage(Config.userToken);
    removeLocalStorage(KEYS.email);
    removeLocalStorage(KEYS.emailId);
    removeLocalStorage(KEYS.meId);
    dispatch(setSelectedLoginUser(null));
    dispatch(setSelectedUserAccounts(null));
    navigate(ROUTES.home);
  };

  const fnLogout = async () => {
    try {
      const response = await fetch(`${Config.serverUrl}api/logout`, {
        method: "GET",
        headers: { Authorization: `Bearer ${isLoggedInUser}` },
      });
      if (response?.status === 200) {
        fnLogoutUser();
      }
    } catch (error) {
      fnLogoutUser();
    } finally {
      fnLogoutUser();
    }
  };

  // const fnLogout = async () => {
  // try {
  //   const response = await fetch(`${Config.serverUrl}api/logout`, {
  //     method: "POST", // Logout typically uses POST
  //     headers: { Authorization: `Bearer ${isLoggedInUser}` },
  //   });

  //   if (!response.ok) {
  //     // Handle non-200 status codes (optional)
  //     console.error("Logout request failed:", response.statusText);
  //   }

  //   // Invalidate cache manually (assuming RTKQ)
  //   juriiApi.unsubscribeTag('user'); // Replace 'user' with your actual tag

  //   // Clear user data (optional)
  //   localStorage.removeItem(Config.userToken); // Assuming token storage

  //   // Redirect or handle logout (replace with your logic)
  //   window.location.href = "/login"; // Redirect to login page
  // } catch (error) {
  //   console.error("Logout error:", error);
  //   // Handle logout errors (optional)
  // }
  // }
  return (
    <button onClick={() => fnLogout()} class="flex flex-row ... gap-2">
      <LogoutIcon className={"cursor-pointer"} />
      <DZText
        className=" cursor-pointer text-[14px] text-[#112D4E] group-hover:translate-x-[4px] transition"
        children={"Logout"}
      />
    </button>
  );
}

export default Logout;
